<template>
    <!-- BEGIN: Content-->
    <div class="productList">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

            <v-btn
              color="#000000"
              class="white--text"
              depressed
              @click.stop="dialog = true"
            >
              Item
              <v-icon
                right
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-3">
                <v-card-title>
                    <h2>Items</h2>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchRes"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              <v-data-table
                dense
                :headers="fields"
                :items="productsLoad"
                class="elevation-0"
                loading="false"
                loading-text="Loading... Please wait"

                id="virtual-scroll-table"
                v-scroll:#virtual-scroll-table="onScroll"
                item-key="id"
                disable-pagination
                hide-default-footer
              >

              <template
                v-if="start > 0"
                v-slot:body.prepend
              >
                <tr>
                  <td
                    :colspan="fields.length"
                    :style="'padding-top:'+startHeight+'px'"
                  >
                  </td>
                </tr>
              </template>
              <template
                v-if="start + perPage < this.$store.state.items.length"
                v-slot:body.append
              >
                <tr>
                  <td
                    :colspan="fields.length"
                    :style="'padding-top:'+endHeight+'px'"
                  >
                  </td>
                </tr>
              </template>

                <template v-slot:item.#="{ index }">
                  {{ index + 1 }}
                </template>
                <!-- <template v-slot:item.image="{ item }">
                  <img :src="item.image" style="width:35px;padding: 1%;border-radius: 5px;"/>
                </template> -->
                <template v-slot:item.name="{ item }">
                  {{ item.item_name }}
                </template>
                <template v-slot:item.category="{ item }">
                  {{ item.category }}
                </template>
                <template v-slot:item.unit="{ item }">
                  {{ item.unit }}
                </template>
                <template v-slot:item.quantity="{ item }">
                  <span v-if="item.current_stock == null">{{ item.quantity }}</span>
                    <span v-if="item.current_stock != null && item.current_stock != 0">{{ item.current_stock }}</span>
                    <span v-if="item.current_stock == 0" style="color:#E3AF00">Out of Stock</span>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.price | currency('') }}
                </template>
                <template v-slot:item.cost="{ item }">
                  {{ item.cost | currency('') }}
                </template>
                <template v-slot:item.sku="{ item }">
                  {{ item.sku }}
                </template>
                <template v-slot:item.barcode="{ item }">
                  {{ item.barcode }}
                </template>
                <template v-slot:item.action="{ item }">
                  <div style="width:100%;display:flex">
                    <v-icon
                      small
                      class="mx-3"
                      @click="addStock(item.id)"
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon
                      small
                      class="mx-3"
                      @click="edit(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      class="mx-3"
                      @click="delete(item.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Item</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <div class="row">
                    <div class="col-lg-8">
                      <img :src="previewImage" style="width:30%;"  />
                    </div>
                </div>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      prepend-icon="mdi-camera"
                      label="Select vehicle image"
                      v-on:change="onChange"
                      id="image" 
                      name="image"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Item Name*"
                      v-model="item.item_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="categories"
                      label="Select category"
                      v-model="item.category_id"
                      item-text="category_name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="search"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="units"
                      label="Select unit"
                      v-model="item.unit_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchunit"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Quantity*"
                      required
                      v-model="item.quantity"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Price"
                      v-model="item.price"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Cost"
                      v-model="item.cost"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Sku"
                      v-model="item.sku"
                      :rules="fieldRules"
                      :value="timemilliseconds"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Barcode"
                      v-model="item.barcode"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="dark darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Item</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <div class="row">
                    <div class="col-lg-8">
                      <img :src="updatepreviewImage" style="width:30%;"  />
                    </div>
                </div>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      prepend-icon="mdi-camera"
                      label="Select vehicle image"
                      v-on:change="onChange"
                      id="image" 
                      name="image"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Item Name*"
                      v-model="item.updateitem_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="categories"
                      label="Select category"
                      v-model="item.updatecategory_id"
                      :value="item.updatecategory_id"
                      item-text="category_name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="search"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="units"
                      label="Select unit"
                      v-model="item.updateunit_id"
                      :value="item.updateunit_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      :search-input.sync="searchunit"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Quantity*"
                      required
                      v-model="item.updatequantity"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Price"
                      v-model="item.updateprice"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Cost"
                      v-model="item.updatecost"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Sku"
                      v-model="item.updatesku"
                      :rules="fieldRules"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Barcode"
                      v-model="item.updatebarcode"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="dark darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

      <!-- add stock modal-->
      <v-dialog
        v-model="addstockdialog"
        width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Stock</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Item Name*"
                      v-model="item.addstockitem_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Quantity*"
                      required
                      v-model="item.addstockquantity"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="dark darken-1"
                text
                @click="addstockdialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="stocktaking"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update stock -->

    </div>

</template>
<style scope>
@media (min-width: 576px){
    .modal-slide-in .modal-dialog {
        width: 48rem;
    }
}
.vs__selected, .vs__search,.vs__search:focus{
    color: #B4B7BD !important;
}
.vs__open-indicator,.vs__clear{
    fill:  #B4B7BD !important;
}
#virtual-scroll-table {
  max-height: 65vh;
  overflow: auto;
}
/*#virtual-scroll-table {
  max-height: 600px;
  overflow: auto;
}*/
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import Pusher from 'pusher-js'
import {ability} from '../../services/ability'

export default {
    name:"productList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          searchfilter: '',
          search:null,
          valid: true,
          searchunit:null,
          dialog: false,
          updatedialog: false,
          addstockdialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Products',
              disabled: false,
              href: '',
            },
            {
              text: 'Items',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            // { text: 'Image', value: 'image' },
            { text: 'Name', value: 'name' },
            { text: 'Category', value: 'category' },
            { text: 'Unit', value: 'unit' },
            { text: 'Quantity', value: 'quantity' },
            { text: 'Price', value: 'price' },
            { text: 'Cost', value: 'cost' },
            { text: 'Sku', value: 'sku' },
            { text: 'Barcode', value: 'barcode' },
            { text: 'Action', value: 'action' },
           ],
           previewImage: null,
           updatepreviewImage: null,
          item:{
              item_name:"",
                category_id:"",
                unit_id:"",
                quantity:"",
                price:"",
                cost:"",
                sku:"",
                barcode:"",
              image:null,

              addstockquantity: "",
              addstockitem_name: "",

              updateitem_name:"",
                updatecategory_id:"",
                updateunit_id:"",
                updatequantity:"",
                updateprice:"",
                updatecost:"",
                updatesku:"",
                updatebarcode:"",

              updateID: "",
              addStockID: "",
              _method:"patch"
          },
          isBusy: false,
          loading: false,
           disabled: false,
            updateModal: null,
            products:[],
          start: 0,
          timeout: null,
          rowHeight: 24,
          perPage: 25,
          searchRes: ""
        }
    },
    beforeMount() {
      if(!ability.can('inventory-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    destroyed () {
      this.$barcodeScanner.destroy()
    },
    serverPrefetch () {
      return this.getProducts()
    },
    mounted(){      
      if(!this.productsLoad || this.productsLoad.length == 0) {
        this.getProducts()
      }
      this.$barcodeScanner.init(this.onBarcodeScanned)
      this.timemilliseconds()
    },
    computed: {
      
      categories() {
        return this.$store.state.categories
      },
      units() {
        return this.$store.state.units
      },
      productsLoad() {
        return this.$store.state.items.filter(item => {

        const itemname = item.item_name.toString().toLowerCase()

        let searchTerm = ''
          if(this.searchRes !== '') {
            searchTerm = this.searchRes.toString().toLowerCase()
          }
          return itemname.includes(searchTerm)

        }).slice(this.start, this.perPage+this.start)
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.$store.state.items.length - this.start);
      }
      
    },
    methods:{
      timemilliseconds() {
        let d = new Date();
        let n = d.valueOf();
        this.item.sku = n
      },
    onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.$store.state.items.length ?
            this.$store.state.items.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
    onBarcodeScanned (scn) {
      this.item.barcode = scn
    },
    resetBarcode () {
      let barcode = this.$barcodeScanner.getPreviousCode()
    },
      onChange(e) {
        if(e) {
          this.item.image = e;
          this.previewImage = URL.createObjectURL(this.item.image);
        }
      },
      async getProducts(){
        this.$store
        .dispatch('getDashboard')
        .then(() => {
        })
        .catch(() => {
        })
    },
      clear () {
          this.$refs.form.reset()
        },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('item_name',this.item.item_name)
            fd.append('category_id',this.item.category_id)
            fd.append('unit_id',this.item.unit_id)
            fd.append('quantity',this.item.quantity)
            fd.append('price',this.item.price)
            fd.append('cost',this.item.cost)
            fd.append('sku',this.item.sku)
            fd.append('barcode',this.item.barcode)
            fd.append('file',this.item.image)

            await axios.post('/item', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.$toast.success('Item added successfully','Success',{position: 'topRight',timeout: 7000,})
              this.getProducts()
              this.previewImage = null
              this.clear()
              this.timemilliseconds()
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
             fd.append('name',this.item.updatename)
             fd.append('registration',this.item.updateregistration)
            fd.append('item_name',this.item.updateitem_name)
            fd.append('category_id',this.item.updatecategory_id)
            fd.append('unit_id',this.item.updateunit_id)
            fd.append('quantity',this.item.updatequantity)
            fd.append('price',this.item.updateprice)
            fd.append('cost',this.item.updatecost)
            fd.append('sku',this.item.updatesku)
            fd.append('barcode',this.item.updatebarcode)
            fd.append('file',this.item.image)
            fd.append('_method',this.item._method)

            await axios.post(`/item/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
                this.$toast.success('Item updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getProducts()
                this.updatedialog = false
              this.previewImage = null
              this.item.updateID = ''
              this.timemilliseconds()
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
        async stocktaking(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('item_name',this.item.addstockitem_name)
            fd.append('quantity',this.item.addstockquantity)

            await axios.post(`/add-stock/${this.item.addStockID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
                this.$toast.success('Stock updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getProducts()
                this.addstockdialog = false
              this.clear()
              this.item.addStockID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async edit(id) {
        let index = this.$store.state.items.filter(item => item.id === id );
          this.item.updateitem_name = index[0].item_name
          this.item.updatecategory_id = index[0].category_id
          this.item.updateunit_id = index[0].unit_id
          this.item.updatequantity = index[0].quantity
          this.item.updateprice = index[0].price
          this.item.updatecost = index[0].cost
          this.item.updatesku = index[0].sku
          this.item.updatebarcode = index[0].barcode
          this.updatepreviewImage = index[0].image
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      addStock(id) {
        let index = this.$store.state.items.filter(item => item.id === id );
        this.item.addstockitem_name = index[0].item_name
        this.item.addStockID = index[0].id
        this.addstockdialog = true
    },
      delete(id){
            if(confirm("Are you sure to delete vehicle ?")){
                axios.delete(`/vehicle/${id}`).then(response=>{
                  this.$toast.success('Vehicle deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.getProducts()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }
    }
}
</script>