import { render, staticRenderFns } from "./Pos.vue?vue&type=template&id=72de2e0e"
import script from "./Pos.vue?vue&type=script&lang=js"
export * from "./Pos.vue?vue&type=script&lang=js"
import style0 from "./Pos.vue?vue&type=style&index=0&id=72de2e0e&prod&lang=css"
import style1 from "./Pos.vue?vue&type=style&index=1&id=72de2e0e&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports