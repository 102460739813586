<template>
  <div class="dashboard">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card class="card-shadow">
              <vue-element-loading
                :active="show"
                spinner="bar-fade-scale"
                color="#FF6700"
              />
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:1.05rem !important;width:100%">
                  Items with Highest Profit - {{ monthname }}
                </p>
              </v-card-title>
              <v-card-text>
                <highcharts class="saleshc" :options="bestSalesOptions" ref="saleschart"></highcharts>
              </v-card-text>
            </v-card>
          </v-col>
          
          <v-col cols="12" sm="12" md="8">
            <v-card class="card-shadow">
              <vue-element-loading
                :active="show"
                spinner="bar-fade-scale"
                color="#FF6700"
              />
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:1.05rem !important;width:100%">
                 Monthly Sales & Profits {{ currentyear }}
                </p>
              </v-card-title>
              <v-card-text>
                  <highcharts class="hc" id="container" :options="chartOptions" ref="chart"></highcharts>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card class="card-shadow" style="height:100% !important">
              <vue-element-loading
                :active="show"
                spinner="bar-fade-scale"
                color="#FF6700"
              />
              <v-card-title>
                <p class="text-h6 text--primary text-center mb-0" style="font-size:1.05rem !important">
                  Sales & Profit Summary {{ currentyear }}
                </p>
              </v-card-title>
              <v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Time frame
                        </th>
                        <th class="text-left">
                          Sales
                        </th>
                        <th class="text-left">
                          Profit
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="(today) in today_booking"
                      >
                        <td>Today</td>
                        <td>{{ today.gross_sale | currency('',0) }}</td>
                        <td>{{ today.profit | currency('',0) }}</td>
                      </tr>
                      <tr
                      v-for="(week) in weeks_booking"
                      >
                        <td>This Week</td>
                        <td>{{ week.gross_sale | currency('',0) }}</td>
                        <td>{{ week.profit | currency('',0) }}</td>
                      </tr>
                      <tr
                      v-for="(month) in months_booking"
                      >
                        <td>This Month</td>
                        <td>{{ month.gross_sale | currency('',0) }}</td>
                        <td>{{ month.profit | currency('',0) }}</td>
                      </tr>
                      <tr
                      v-for="(year) in years_booking"
                      >
                        <td>This Year</td>
                        <td>{{ year.gross_sale | currency('',0) }}</td>
                        <td>{{ year.profit | currency('',0) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>
      </v-container>
  </div>
</template>
<style scope>
   .bookingimg{
        width: 150px !important;
        position: absolute;
        right: -1rem;
        bottom: 3rem;
   }
   
</style>
<script>
import axios from 'axios'

import "echarts";
import VChart, { THEME_KEY } from "vue-echarts";
import VueElementLoading from "vue-element-loading";

export default {
    name: "dashboard",
    components: {
      VChart,
      VueElementLoading
    },
    provide: {
      [THEME_KEY]: "light"
    },
    data: () => ({
      charts: [],
      show: true,
      monthname: '',
      currentyear: '',
      fields: [
        '#',
        { key: 'Name', stickyColumn: true, isRowHeader: true, variant: 'dark' },
        'Email',
        'Telephone',
        'Address',
        'Category',
        ],
       today_booking: [],
       weeks_booking: [],
       months_booking: [],
       years_booking: [],

        chartOptions: {
          title: {
              text: ''
          },
          labels: {
              items: [{
                  html: 'Monthly Profit Margin %',
                  style: {
                      left: '120px',
                      top: '0px',
                      color: 'black'
                  }
              }]
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Total Sale (Kes)'
              }
          },
          xAxis: {
              categories: []
          },
          series: []
        },

        bestSalesOptions: {
          title: {
              text: ''
          },
          yAxis: {
              min: 0,
              title: {
                  text: 'Profit (Kes)'
              }
          },
          xAxis: {
              categories: []
          },
          series: []
        }


    }),
    serverPrefetch () {
      return this.getDashboard()
    },
    created() {
      const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

      const d = new Date();
      this.monthname = month[d.getMonth()];
      this.currentyear = d.getFullYear();
    },
    beforeMount() {
      // if(!ability.can('user-management', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    mounted(){
      this.name = this.$store.state.user.user.name
      if(!this.allitems || this.allitems.length == 0) {
        this.getDashboard()
      }
      this.getReports()
    },
    computed: {
      chart() {
        return this.$store.state.chart
      },
      orgchartbooking() {
        return []
      },
      statisticsChart() {
        return []
      },
      allitems () {
        return this.$store.state.items
      },
    },
    methods: {
      
      async getReports(){
        await axios.get('/dashboard/charts').then(response=>{
            this.show = false

            this.charts = response.data.charts
            this.today_booking = response.data.today_booking
            this.weeks_booking = response.data.weeks_booking
            this.months_booking = response.data.months_booking
            this.years_booking = response.data.years_booking


            this.chartOptions.xAxis.categories = this.charts.months
            this.chartOptions.series = this.charts.series

            this.bestSalesOptions.xAxis.categories = this.charts.itemlabels
            this.bestSalesOptions.series = this.charts.bestsales

        }).catch(error=>{
            console.log(error)
            this.show = false
        })
      },

      async getDashboard(){
        this.$store
          .dispatch('getDashboard')
          .then(() => {
          })
          .catch(() => {
          })
      },

    }
}
</script>