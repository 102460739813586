<template>
    <!-- BEGIN: Content-->
    <div class="categoryList">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

            <v-btn
              color="#000000"
              class="white--text"
              depressed
              @click.stop="dialog = true"
            >
              Category
              <v-icon
                right
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-3">
              <v-data-table
                dense
                :headers="fields"
                :items="categories"
                class="elevation-0"
                loading="false"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.#="{ item }">
                  {{ item.created_at }}
                </template>
                <template v-slot:item.name="{ item }">
                  {{ item.category_name }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="edit(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Category</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Category Name*"
                      v-model="item.category_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="2"
                    v-for="(color, index) in colors"
                    :key="index"
                  >
                    <v-radio-group
                      v-model="item.color_id"
                    >
                      <v-radio
                        :label="`${color.name}`"
                        :value="`${color.id}`"
                        :color="`${color.color}`"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Category</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Category Name*"
                      v-model="item.updatecategory_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                      <label>Category Color</label>
                      <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                            md="2"
                            v-for="(color, index) in colors"
                            :key="index"
                          >
                            <v-radio-group
                              v-model="item.updatecolor_id"
                            >
                              <v-radio
                                :label="`${color.name}`"
                                :value="`${color.id}`"
                                :color="`${color.color}`"
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                      </v-row>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>

<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import {ability} from '../../services/ability'

export default {
    name:"categoryList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Products',
              disabled: false,
              href: '',
            },
            {
              text: 'Categories',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: 'created_at',
            },
            { text: 'Category Name', value: 'name' },
            { text: 'Action', value: 'action' },
           ],
          item:{
                category_name:"",
                color_id:"",
                updatecategory_name: "",
                updatecolor_id:"",
                updateID: "",
                _method:"patch"
            },
            loading: false,
           disabled: false,
           isBusy: false,
            updateModal: null
        }
    },
    beforeMount() {
      if(!ability.can('inventory-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    mounted(){
      if(!this.categories || this.categories.length == 0) {
        this.getCategories()
      }
    },
    computed: {
      categories() {
        return this.$store.state.categories
      },
      colors() {
        return this.$store.state.colors
      },
    },
    methods:{
      async getCategories(){
        this.isBusy = true
        await axios.get('/category').then(response=>{
            this.$store.state.categories = response.data.categories
            this.$store.state.colors = response.data.colors
        }).catch(error=>{
            console.log(error)
        })
      },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('category_name',this.item.category_name)
            fd.append('color_id',this.item.color_id)

            await axios.post('/category', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Category added successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.getCategories()
                this.clear()
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

        }

        },
        clear () {
          this.$refs.form.reset()
        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('category_name',this.item.updatecategory_name)
            fd.append('color_id',this.item.updatecolor_id)
            fd.append('_method',this.item._method)

            await axios.post(`/category/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              if(response.data.status === parseInt(200))
                this.$toast.success("Category updated successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.updatedialog = false
                this.getCategories()
              this.clear()
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async edit(id) {
        let index = this.categories.filter(item => item.id === id );
        this.item.updatecategory_name = index[0].category_name
        this.item.updatecolor_id = index[0].color_id
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      deleteItem(id){
            if(confirm("Are you sure to delete category ?")){
                axios.delete(`/category/${id}`).then(response=>{
                  this.$toast.success('Category deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                    this.getCategories()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

    }
}
</script>