import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	 theme: {
	    themes: {
	      light: {
	        primary: '#3A5553',
	        secondary: '#405C5A',
	        accent: '#f9780d',
	        error: '#ED7779',
	      },
	    },
	  },
});
