<template>
    <!-- BEGIN: Content-->

    <div class="StoreList">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

            <v-btn
              color="#000000"
              class="white--text"
              depressed
              @click.stop="dialog = true"
            >
              Store
              <v-icon
                right
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-3">
              <v-data-table
                dense
                :headers="fields"
                :items="allstores"
                class="elevation-0"
                loading="false"
                :search="searchfilter"
                loading-text="Loading... Please wait"
              >
              <template v-slot:top>
                <v-text-field
                  v-model="searchfilter"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
              </template>
                <template v-slot:item.name="{ item }">
                  {{ item.store.store_name }}
                </template>
                <template v-slot:item.address="{ item }">
                  {{ item.store.address }}
                </template>
                <template v-slot:item.phoneno="{ item }">
                  {{ item.store.phone }}
                </template>
                <template v-slot:item.description="{ item }">
                  {{ item.store.description }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip
                    dark
                    small
                    v-if="item.status"
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="connectStore(item.id)"
                  >
                    mdi-connection
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    @click="edit(item.store.id)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="delete(item.store.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Store</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <div class="row">
                    <div class="col-lg-8">
                      <img :src="previewImage" style="width:30%"  />
                    </div>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Store Name*"
                      v-model="item.store_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Address"
                      v-model="item.address"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Phone No"
                      v-model="item.phone"
                      :rules="fieldRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      prepend-icon="mdi-camera"
                      label="Select logo"
                      v-on:change="onChange"
                      id="image" 
                      name="image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Description"
                      v-model="item.description"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Store</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <div class="row">
                    <div class="col-lg-8">
                      <img :src="previewImage" style="width:30%"  />
                    </div>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Store Name*"
                      v-model="item.updatestore_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Address"
                      v-model="item.updateaddress"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Phone No"
                      v-model="item.updatephone"
                      :rules="fieldRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/webp"
                      prepend-icon="mdi-camera"
                      label="Select logo"
                      v-on:change="onChange"
                      id="image" 
                      name="image"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Description"
                      v-model="item.updatedescription"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>
<style scope>
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }
    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import Pusher from 'pusher-js'
import {ability} from '../../services/ability'

export default {
    name:"StoreList",
    data(){
        return {
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter: '',
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          previewImage: null,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Settings',
              disabled: false,
              href: '',
            },
            {
              text: 'Stores',
              disabled: false,
              href: '',
            },
          ],
          item:{
                store_name:"",
                res: null,
                address:"",
                phone:"",
                description:"",
                logo:null,

                updatestore_name:"",
                updateres: null,
                updateaddress:"",
                updatephone:"",
                updatedescription:"",
                updatelogo:"",

                updateID: "",
                _method:"patch"
            },
            isBusy: false,
            loading: false,
            tableloading:true,
           disabled: false,
           fields: [
            { text: 'Store Name', value: 'name' },
            { text: 'Address', value: 'address' },
            { text: 'Phone No', value: 'phoneno' },
            { text: 'Description', value: 'description' },
            { text: 'Status', value: 'status' },
            { text: 'Action', value: 'action' },
           ],
            updateModal: null
        }
    },
    mounted(){

        this.getStores()

    },
    serverPrefetch () {
    },
    computed: {
      allstores() {
        return this.$store.state.stores
      }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    methods:{
      onChange(e) {
        if(e) {
          this.item.logo = e;
          this.previewImage = URL.createObjectURL(this.item.logo);
        }
      },
      async getStores(){

        await axios.get('/store').then(response=>{
            this.$store.state.stores = response.data
        }).catch(error=>{
            console.log(error)
        })
      },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('store_name',this.item.store_name)
            fd.append('address',this.item.address)
            fd.append('phone',this.item.phone)
            fd.append('description',this.item.description)
            fd.append('file',this.item.logo)

            await axios.post('/store', fd, config).then(response=>{

                if(response.data.status == parseInt(200)) {
                  this.$toast.success('Store added successfully','Success',{position: 'topRight',timeout: 7000,})
                  this.getStores()
                }
                this.loading = false
                this.disabled = false
                this.dialog = false
                this.previewImage = null
                this.clear()
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
                this.dialog = false
              })

          }

        },
        clear () {
          this.$refs.form.reset()
        },
        async update(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('store_name',this.item.updatestore_name)
            fd.append('address',this.item.updateaddress)
            fd.append('phone',this.item.updatephone)
            fd.append('description',this.item.updatedescription)
            fd.append('file',this.item.logo)
            fd.append('_method',this.item._method)

            await axios.post(`/store/${this.item.updateID}`, fd, config).then(response=>{
                this.$toast.success('Store updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getStores()
                this.loading = false
                this.disabled = false
                this.dialog = false
                this.updateModal.hide()
                this.clear()
                this.item.updateID = ''
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
                this.dialog = false
              })

          }

        },
        connectStore(id){
            axios.get(`/connect-store/${id}`).then(response=>{

              if(response.data.status == parseInt(200)) {
                this.$store.state.user.user = response.data.user
                let local = JSON.parse(localStorage.getItem('user'))
                local.user = response.data.user
                localStorage.setItem('user', JSON.stringify(local));
                this.$toast.success('New store connected successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getStores()
              }
                
            }).catch(error=>{
                console.log(error)
            })
        },
      async edit(id) {
        let index = this.allstores.filter(item => item.id === id );

        this.item.updatestore_name = index[0].store_name
          this.item.updatephone = index[0].phone
          this.item.updateaddress = index[0].address
          this.item.updatelogo = index[0].logo
          this.item.updatedescription = index[0].description
          this.item.updateID = index[0].id
          this.updatedialog = true

      },
      delete(id){
            if(confirm("Are you sure to delete store ?")){

              axios.delete(`/store/${id}`).then(response=>{
                this.$toast.success('Store deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getStores()
              })
              .catch(() => {
                console.log("Unexpected error occured")
              })
              
            }
        }
    }
}
</script>