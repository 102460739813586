<template>
    <!-- BEGIN: Content-->
    <div class="salesSummary">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row>
          <div class="px-3 my-2">
            <v-card class="card-shadow">
              <div class="range-date-picker-container" style="width:25rem !important">
                <transition name="panelIn">
                  <range-date-picker
                    class="range-date-picker"
                    v-model="dates"
                    language="en"
                    v-show="showDatePicker"
                    @closePicker="closePicker"
                  ></range-date-picker>
                </transition>
                <v-text-field
                  v-model="rangeDates"
                  @click="showPicker"
                  hide-details
                  flat solo
                  class="drenagepicker"
                ></v-text-field>
              </div>
            </v-card>
          </div>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-layout align-center justify-center class="px-0 my-1">
               <v-flex xs12 sm4 md4>
                <v-card class="card-shadow mx-1">
                  <h3 style="width:100%;text-align:center;font-size:2rem">{{ grosssale | currency('') }}</h3>
                  <p style="width:100%;text-align:center;margin-bottom:0 !important">Gross Sale</p>
                </v-card>
               </v-flex>
               <v-flex xs12 sm4 md4>
                <v-card class="card-shadow mx-1">
                  <h3 style="width:100%;text-align:center;font-size:2rem">{{ costofgoods | currency('') }}</h3>
                  <p style="width:100%;text-align:center;margin-bottom:0 !important">Cost of Goods</p>
                </v-card>
               </v-flex>
               <v-flex xs12 sm4 md4>
                <v-card class="card-shadow mx-1">
                  <h3 style="width:100%;text-align:center;font-size:2rem">{{ grossprofit | currency('') }}</h3>
                  <p style="width:100%;text-align:center;margin-bottom:0 !important">Gross Profit</p>
                </v-card>
               </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" sm="12" style="z-index: 1 !important;">
            <v-card class="card-shadow pb-4">
                <vue-element-loading
                  :active="show"
                  spinner="bar-fade-scale"
                  color="#FF6700"
                />
                <v-card-title>
                    <h2>Sales Summary</h2>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="searchRes"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="mr-3"
                  ></v-text-field>
                  <v-btn
                    style="width:auto !important;height:auto !important;margin:.4rem !important"
                    color="#000000"
                    class="white--text"
                    depressed
                    :loading="loadingpdf"
                    @click="exportpdf"
                  >
                    <v-icon
                      dark
                      size="30"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                  <v-btn
                    style="width:auto !important;height:auto !important;margin:.4rem !important"
                    color="accent"
                    class="black--text"
                    depressed
                    :loading="loadingexcel"
                    @click="exportexcel"
                  >
                    <v-icon
                      dark
                      size="30"
                    >
                      mdi-file-excel-box
                    </v-icon>
                  </v-btn>
                </v-card-title>
              <v-data-table
                dense
                :headers="fields"
                :items="reports"
                class="elevation-0"
                loading="false"
                loading-text="Loading... Please wait"
                fixed-header

                id="virtual-scroll-table"
                v-scroll:#virtual-scroll-table="onScroll"
                item-key="id"
                disable-pagination
                hide-default-footer
                height="100%"
              >

                <template v-slot:item.#="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.date="{ item }">
                  {{ item.date }}
                </template>
                <template v-slot:item.grosssales="{ item }">
                  {{ item.gross_sale | currency('') }}
                </template>
                <template v-slot:item.discount="{ item }">
                  {{ item.discount | currency('') }}
                </template>
                <template v-slot:item.netsales="{ item }">
                  {{ item.net_sale | currency('') }}
                </template>
                <template v-slot:item.costofgoods="{ item }">
                  {{ item.totalsale | currency('') }}
                </template>
                <template v-slot:item.grossprofit="{ item }">
                  {{ item.profit | currency('') }}
                </template>
                <template v-slot:item.margin="{ item }">
                  {{ item.margin.toFixed(2) }}%
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>

</template>
<style scoped>
  .card-shadow .v-btn:not(.v-btn--round).v-size--default {
      height: auto !important;
      min-width: auto !important;
      padding: 0 !important;
  }
</style>
<style>
@media (min-width: 576px){
    .modal-slide-in .modal-dialog {
        width: 48rem;
    }
}
.vs__selected, .vs__search,.vs__search:focus{
    color: #B4B7BD !important;
}
.vs__open-indicator,.vs__clear{
    fill:  #B4B7BD !important;
}
#virtual-scroll-table {
  max-height: 65vh;
  overflow: auto;
}
/*#virtual-scroll-table {
  max-height: 600px;
  overflow: auto;
}*/
.range-date-input{
  background: transparent !important;
  font-size: .9rem;
}
.input-group-merge .input-group-text{
  background: transparent !important;
  border-right: none !important;
}
.picker.range-date-picker{
  position: fixed !important;
  margin-top: 2.4rem !important;
  z-index: 1000000000 !important;
}
.v-text-field input{
  padding: 0 !important;
}
.range-date-picker-container .v-text-field {
    padding: 0 !important;
    margin-top: 0 !important; 
}
.salesSummary .v-text-field.v-text-field--solo .v-input__control {
    min-height: 38px !important;
}
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import Pusher from 'pusher-js'
import {ability} from '../../../services/ability'
import VueElementLoading from "vue-element-loading"
import RangeDatePicker from 'vue-easy-range-date-picker'
import { parseTime } from "../../../util.js"

export default {
    name:"salesSummary",
    components: {
      VueElementLoading,
      RangeDatePicker 
    },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          searchfilter: '',
          show: false,
          showDatePicker: false,
          search:null,
          dates: {},
          daterange: {
            start: '',
            end: ''
          },
          valid: true,
          searchunit:null,
          dialog: false,
          updatedialog: false,
          addstockdialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Reports',
              disabled: false,
              href: '',
            },
            {
              text: 'Sales Summary',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Date', value: 'date' },
            { text: 'Gross Sales', value: 'grosssales' },
            { text: 'Discount', value: 'discount' },
            { text: 'Net Sales', value: 'netsales' },
            { text: 'Cost of Goods', value: 'costofgoods' },
            { text: 'Gross Profit', value: 'grossprofit' },
            { text: 'Margin', value: 'margin' },
           ],
          isBusy: false,
          loading: false,
          disabled: false,
          updateModal: null,
          loadingexcel: false,
          loadingpdf: false,
          start: 0,
          timeout: null,
          rowHeight: 24,
          perPage: 25,
          searchRes: "",
          grosssale: 0,
          costofgoods: 0,
          grossprofit: 0
        }
    },
    beforeMount() {
      if(!ability.can('inventory-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    serverPrefetch () {
      return this.getReports()
    },
    mounted(){      
      // if(!this.reports || this.reports.length == 0) {
      //   this.getReports()
      // }
      this.getReports()
    },
    computed: {
      rangeDates() {
        if (Object.keys(this.dates).length === 2) {
          var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

          this.daterange.start = this.parseTime(this.dates.start,"y-m-d")
          this.daterange.end = this.parseTime(this.dates.end,"y-m-d")

          var startD  = new Date(this.dates.start)
          var endD  = new Date(this.dates.end)

          this.getReports()

          return `${startD.toLocaleDateString("en-US", options)}  -  ${endD.toLocaleDateString("en-US", options)}`;

        } else {

          var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
          var today  = new Date()

          this.daterange.start = this.parseTime(Date.now(),"y-m-d")
          this.daterange.end = this.parseTime(Date.now(),"y-m-d")
          return `${today.toLocaleDateString("en-US", options)}  -  ${today.toLocaleDateString("en-US", options)}`;

        }
      },
      reports() {
        return this.$store.state.reports
      }
      
    },
    methods:{
      parseTime,
      closePicker() {
        this.showDatePicker = false;
      },
      showPicker() {
        this.showDatePicker = true;
      },
      onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.$store.state.reports.length ?
            this.$store.state.reports.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      async getReports(){
        this.show = true

        await axios.get(`/reports/sales-summary/${this.daterange.start}/${this.daterange.end}`).then(response=>{
            this.show = false
            if(response.status == parseInt(200)) {
                this.$store.state.reports = response.data.reports
                this.grosssale = this.$store.state.reports.reduce((acc, item) => acc + item.gross_sale, 0)
                this.costofgoods = this.$store.state.reports.reduce((acc, item) => acc + item.totalsale, 0)
                this.grossprofit = this.$store.state.reports.reduce((acc, item) => acc + item.profit, 0)
            }
        }).catch(error=>{
            console.log(error)
            this.show = false
        })
    },
    exportexcel(){
        this.loadingexcel = true

        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'arraybuffer' };
          let fd = new FormData();
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/sales-summary/export/excel', fd, config).then(response=>{
          this.loadingexcel = false
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'sale_summary_report.xlsx');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingexcel = false
          console.log("Unexpected error occured")
        })
      },
      exportpdf(){
        this.loadingpdf = true
        
        const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: 'blob' };
          let fd = new FormData();
          fd.append('start',this.daterange.start)
          fd.append('end',this.daterange.end)

        axios.post('/sales-summary/export/pdf', fd, config).then(response=>{
         this.loadingpdf = false
         var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
         var fileLink = document.createElement('a');
         fileLink.href = fileURL;
         fileLink.setAttribute('download', 'sales_summary_report.pdf');
         document.body.appendChild(fileLink);
         fileLink.click();
          
        })
        .catch(() => {
          this.loadingpdf = false
          console.log("Unexpected error occured")
        })
      },
  }
}
</script>

