<template>

<div class="Signup">
       <v-container fluid fill-height style="margin-top:1rem">
          <v-layout align-center justify-center>
             <v-flex xs12 sm8 md4>
              <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
                <v-card class="card-shadow px-3">
                    <v-card-title color="primary">
                        <v-row>
                          <v-col cols="12" class="px-0">
                            <img src="/assets/tpos.png" style="width:20% !important;text-align:center">
                            <h2 class="card-title fw-bold mb-0 mt-3">TPOS - Point of Sale</h2>
                              <p class="card-text mb-0">Your selling made and decision making made easy!</p>
                          </v-col>
                        </v-row>
                      </v-card-title>
                   <v-card-text style="padding:0 !important">

                    <v-stepper v-model="stepper">
                        <v-stepper-header text>
                            
                            <div class="step" v-for="(step, index) in steps" :key=index>
                                <v-stepper-step
                                    :edit-icon="'mdi-check'"
                                    :complete-icon="'mdi-pencil'"
                                    :step="index + 1"
                                    :complete="(index + 1 ) <= stepper"
                                    :editable="(index + 1) < stepper">{{ step.label }}</v-stepper-step>
                                <v-divider></v-divider>
                            </div>
                            
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        prepend-icon="mdi-account"
                                        name="name"
                                        label="Full names*"
                                        v-model="form.name"
                                        :rules="fieldRules"
                                     ></v-text-field>
                                   </v-col>
                                   <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        prepend-icon="mdi-phone"
                                        name="telephone"
                                        label="Telephone*"
                                        v-model="form.telephone"
                                        :rules="fieldRules"
                                     ></v-text-field>
                                   </v-col>
                                   <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        prepend-icon="mdi-card"
                                        name="idno"
                                        label="ID NO*"
                                        v-model="form.idno"
                                        :rules="fieldRules"
                                     ></v-text-field>
                                   </v-col>
                                   <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        prepend-icon="mdi-email"
                                        name="email"
                                        label="Email Address*"
                                        v-model="form.email"
                                        :rules="emailRules"
                                     ></v-text-field>
                                   </v-col>
                                   <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        id="password"
                                        prepend-icon="mdi-lock"
                                        name="password"
                                        label="Password*"
                                        type="password"
                                        v-model="form.password"
                                        :rules="fieldRules"
                                     ></v-text-field>
                                   </v-col>
                                   <v-col
                                      cols="12"
                                      sm="6"
                                      md="6"
                                    >
                                     <v-text-field
                                        id="cpassword"
                                        prepend-icon="mdi-lock"
                                        name="confrim password"
                                        label="Confirm Password*"
                                        type="password"
                                        v-model="form.password_confirmation"
                                        :rules="fieldRules"
                                     ></v-text-field>
                                   </v-col>
                                 </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn text @click.native="stepper = 1">Back</v-btn>
                                        <v-btn text color="primary" style="float:right !important" @click.native="stepper = 2">Continue</v-btn>
                                    </v-col>
                                </v-row>

                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                  >
                                    <v-text-field
                                      label="Store Name*"
                                      v-model="form.store_name"
                                      required
                                      :rules="fieldRules"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                  >
                                    <v-text-field
                                      label="Address"
                                      v-model="form.address"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                  >
                                    <v-text-field
                                      label="Phone No*"
                                      v-model="form.phone"
                                      :rules="fieldRules"
                                      required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-file-input
                                      :rules="imageRules"
                                      accept="image/png, image/jpeg, image/bmp, image/webp"
                                      prepend-icon="mdi-camera"
                                      label="Select logo"
                                      v-on:change="onChange"
                                      id="image" 
                                      name="image"
                                    ></v-file-input>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      label="Description"
                                      v-model="form.description"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn text @click.native="stepper = 1">Back</v-btn>
                                        <v-btn color="primary"
                                          style="float:right !important"
                                          :loading="loading"
                                          :disabled="!valid"
                                          @click="create"
                                          >Register</v-btn>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>

                   </v-card-text>

                   <!-- <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary"
                      :loading="loading"
                      :disabled="!valid"
                      @click="create"
                      >Register</v-btn>
                   </v-card-actions> -->
                   <p class="text-center" style="padding:1rem"><span>Already have an account?</span><router-link :to="{ name: 'Login' }"><span>&nbsp;Sign in instead</span></router-link></p>
                </v-card>
               </v-form>
             </v-flex>
          </v-layout>
       </v-container>
  </div>

</template>
<style scoped>
  .col-sm-12{
    padding: 0 !important;
  }
  .v-sheet.v-stepper:not(.v-sheet--outlined) {
     box-shadow: none !important; 
}
</style>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'Signup',
    components: {
    },
    data: () => ({
        stepper: 1,
        steps: [
            {
                label: 'Personal Info',
                completed: false,
            },
            {
                label: 'Store Details',
                completed: false,
            },
        ],
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      imageRules: [
        value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
      ],
      valid: true,
      authUser:null,
      valid: true,
      loading: false,
      disabled: false,
      snackbar: false,
      type: 'password',
      showPassword: false,
      showCPassword: false,
      previewImage: null,
      form: {
        name: null,
        email: null,
        telephone: null,
        idno: null,
        password: null,
        password_confirmation: null,

        store_name:"",
        address:"",
        phone:"",
        description:"",
        logo:null,
      },
      passwordRules: [
      (v) => !!v || 'Password is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ]
    }),
    methods: {
        onChange(e) {
            if(e) {
              this.form.logo = e;
              this.previewImage = URL.createObjectURL(this.form.logo);
            }
          },
        create() {

          if (this.$refs.form.validate()) {

            this.loading = true
            this.disabled = true
            
            this.$store
            .dispatch('signup', {
                name: this.form.name,
                email: this.form.email,
                telephone: this.form.telephone,
                idno: this.form.idno,
                password: this.form.password,
                password_confirmation: this.form.password_confirmation,

                store_name: this.form.store_name,
                address: this.form.address,
                phone: this.form.phone,
                description: this.form.description,
                file: this.form.logo

            })
            .then(() => {

                this.loading = false
                this.disabled = false

              if(this.$store.state.user.status === parseInt(200))
              {

                this.$toast.success('Signup was successful','Success',{position: 'topRight',timeout: 7000,})
                
                this.$router.push({ name: 'Dashboard' })

              }else{
                this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
              }
            })
            .catch(() => {
                this.loading = false
                 this.disabled = false
                 this.$toast.error('Check your credentials and try again','Error',{position: 'topRight',timeout: 7000,})
                console.log('Unexpected error occured. Please try again!')
            })

          }

        }
    },
    computed: {
        ...mapGetters([
            'isLogged'
            ])
    }
}
</script>