import Vue from 'vue'
import VueRouter from 'vue-router'
import {ability} from '../services/ability'
import { AbilityBuilder, Ability } from '@casl/ability'
const { can, cannot, build } = new AbilityBuilder(Ability)

import Dashboard from "@/components/Dashboard.vue"
import POS from "@/components/views/Pos.vue"
import Receipt from "@/components/receipts/index.vue"
import Profile from "@/components/profile/index.vue"
import ProductList from "@/components/products/index.vue"
import CategoryList from "@/components/categories/index.vue"
import DiscountList from "@/components/discounts/index.vue"
import TaxList from "@/components/taxes/index.vue"
import StoreList from "@/components/stores/index.vue"
import SalesSummary from "@/components/reports/sales_summary/index.vue"
import SalesItem from "@/components/reports/sales_item/index.vue"

import SalesCategory from "@/components/reports/sales_category/index.vue"
import Login from "@/components/views/Login.vue"
import Signup from "@/components/views/Signup.vue"
import ForgotPassword from "@/components/views/forgotpassword.vue"
import ResetPasswordForm from "@/components/views/resetpassword.vue"

import usersList from "@/components/config/users.vue"
import roleList from "@/components/config/roles.vue"
import permissionList from "@/components/config/permissions.vue"

Vue.use(VueRouter)

 const routes = [
 { 
    path: '/forgot/password', 
    name: 'ForgotPassword', 
    component: ForgotPassword, 
    meta: {
      auth: undefined
    }
  },
  { 
    path: '/reset/:token', 
    name: 'ResetPasswordForm', 
    component: ResetPasswordForm, 
    meta: {
      auth: undefined
    } 
  },
 {
    path: '/reports/sales-summary',
    name: 'salesSummary',
    component: SalesSummary,
    meta: {
      auth: true
    }
  },
  {
    path: '/reports/sales-item',
    name: 'salesItem',
    component: SalesItem,
    meta: {
      auth: true
    }
  },
  {
    path: '/reports/sales-category',
    name: 'salesCategory',
    component: SalesCategory,
    meta: {
      auth: true
    }
  },
  {
    path: '/account/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: true
    }
  },
  {
    path: '/point-of-sale',
    name: 'Pos',
    component: POS,
    meta: {
      auth: true
    }
  },
  {
    path: '/sales/receipts',
    name: 'Receipt',
    component: Receipt,
    meta: {
      auth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: undefined
    }
  },
  {
    path: '/auth/register',
    name: 'Signup',
    component: Signup
  },
  {
      name: 'productList',
      path: '/products/list',
      component: ProductList,
      meta: {
        auth: true
      }
  },
  {
      name: 'categoryList',
      path: '/category/list',
      component: CategoryList,
      meta: {
        auth: true
      }
  },
  {
      name: 'discountList',
      path: '/discount/list',
      component: DiscountList,
      meta: {
        auth: true
      }
  },
  {
      name: 'taxList',
      path: '/tax/list',
      component: TaxList,
      meta: {
        auth: true
      }
  },
  {
      name: 'storeList',
      path: '/store/list',
      component: StoreList,
      meta: {
        auth: true
      }
  },
  // users
  {
      name: 'usersList',
      path: '/users/list',
      component: usersList,
      meta: {
        auth: true
      }
  },
  {
      name: 'roleList',
      path: '/role/list',
      component: roleList,
      meta: {
        auth: true
      }
  },
  {
      name: 'permissionList',
      path: '/permission/list',
      component: permissionList,
      meta: {
        auth: true
      }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/auth/login')
    return
  }else {
    next()
  }
  
})

export default router
