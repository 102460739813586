<template>
    <!-- BEGIN: Content-->
    <div class="Receipt">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" sm="12">
            <v-card class="card-shadow pb-3">
                <v-card-title>
                    <h2>Sales Receipts</h2>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="filter"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              <v-data-table
                dense
                :headers="fields"
                :items="filteredRows"
                class="elevation-0"
                loading="false"
                loading-text="Loading... Please wait"

                id="virtual-scroll-table"
                v-scroll:#virtual-scroll-table="onScroll"
                item-key="id"
                disable-pagination
                hide-default-footer
              >

              <template
                v-if="start > 0"
                v-slot:body.prepend
              >
                <tr>
                  <td
                    :colspan="fields.length"
                    :style="'padding-top:'+startHeight+'px'"
                  >
                  </td>
                </tr>
              </template>
              <template
                v-if="start + perPage < this.$store.state.receipts.length"
                v-slot:body.append
              >
                <tr>
                  <td
                    :colspan="fields.length"
                    :style="'padding-top:'+endHeight+'px'"
                  >
                  </td>
                </tr>
              </template>

                <template v-slot:item.#="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.date="{ item }">
                  {{ item.created_at }}
                </template>
                <template v-slot:item.receipt="{ item }">
                  <span v-html="item.package_code" v-if="item.refunded == '0'"></span>
                  <span v-else><span v-html="item.package_code"></span><br>
                                          <span style="color:#FF7200">#Refunded</span></span>
                </template>
                <template v-slot:item.totalamount="{ item }">
                  {{ item.total_amount | currency('') }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                    text
                    color="#405C5A"
                    @click="showReceipt(item.package_code)"
                    class="ma-1"
                  >
                    View receipt
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <vue-element-loading
                  :active="show"
                  spinner="bar-fade-scale"
                  color="primary"
                  background-color="rgba(255,255,255,0)"
                />
            <v-card class="card-shadow px-3" :style="{display:display, height:height}">
              <v-card-title>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-btn
                    style="float:right !important"
                        color="primary darken-1"
                        text
                        @click="refund()"
                        v-if="amt.refunded !== '1'"
                      >
                        <v-icon>mdi-undo</v-icon>
                        Refund Receipt
                      </v-btn>
                      <v-btn
                      style="float:right !important"
                        color="accent darken-1"
                        text
                        @click="printReceipt()"
                      >
                        <v-icon>mdi-printer</v-icon>
                        Print Receipt
                      </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-row id="printReceipt">
                <v-col cols="12" sm="12" class="mx-auto printstyle">
                  <h4 class="text-center" style="margin-top:6% !important;margin-bottom:6% !important;font-size:16px;text-transform:uppercase !important" v-if="active_store != null">
                    <span v-for="(store, index) in $store.state.user.user.store">
                      <b v-if="store.status == 'Active'">{{ store.store_name }}</b>
                    </span>
                  </h4>
                  <h4 class="text-center" style="margin-top:5% !important;margin-bottom:5% !important;font-size:16px; border:1px solid #333">Sales Receipt</h4>
                  <table class="c-table" syle="width:100% !important">
                      <tr>
                          <td>Date:&nbsp;&nbsp;{{ amt.date }}</td>
                          <td style="text-align:left !important">Time:&nbsp;&nbsp;{{ amt.time }}</td>
                      </tr>
                      <tr>
                          <td>Receipt #:</td>
                          <td style="text-align:left !important">{{ amt.package_code }}</td>
                      </tr>
                      <tr>
                          <td>Cashier:</td>
                          <td style="text-align:left !important">{{ amt.cashier }}</td>
                      </tr>
                  </table>
                  <table class="cart-items ctable" style="margin-top:5% !important;width:100% !important">
                      <tr>
                          <td><b>ITEM</b></td>
                          <td><b>QTY</b></td>
                          <td><b>PRICE</b></td>
                      </tr>
                      <tr :key="item.id" v-for="item in items">
                          <td style="text-transform:capitalize;font-weight:500">{{ item.item_name }}</td>
                          <td>
                            x {{ item.quantity }}
                          </td>
                          <td>{{ item.price | currency('') }}</td>
                      </tr>
                  </table>
                  <table class="cart-table citable" style="width:100% !important">
                      <tr>
                          <td><b>Sub Total</b></td>
                          <td><b>{{ amt.subtotal_amount | currency('') }}</b></td>
                      </tr>
                      <tr>
                          <td>Discount</td>
                          <td>{{ amt.discount | currency('') }}%</td>
                      </tr>
                      <tr>
                          <td>Tax(VAT)</td>
                          <td>16%</td>
                      </tr>
                      <tr>
                          <td><b>Total</b></td>
                          <td><b>{{ amt.total_amount | currency('') }}</b></td>
                      </tr>
                      <tr>
                          <td><b>Cash</b></td>
                          <td><b>{{ amt.paid_amount | currency('') }}</b></td>
                      </tr>
                      <tr>
                          <td><b>Change</b></td>
                          <td><b>{{ amt.balance | currency('') }}</b></td>
                      </tr>
                  </table>
                </v-col>
                <v-col cols="12" sm="12" class="footer" style="margin-top:5% !important;">
                  <div class="mx-auto mt-4 text-center">
                    <p><b>Thank you for purchasing with Us!</b><br>
                    WITH YOU, ALL THE WAY</p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </div>

</template>
<style scope>
.cart-items.ctable td:nth-child(3),.cart-table.citable td:nth-child(2){
    text-align: right;
  }
@media (min-width: 576px){
    .modal-slide-in .modal-dialog {
        width: 48rem;
    }
}
.vs__selected, .vs__search,.vs__search:focus{
    color: #B4B7BD !important;
}
.vs__open-indicator,.vs__clear{
    fill:  #B4B7BD !important;
}
#virtual-scroll-table {
  max-height: 65vh;
  overflow: auto;
}
/*#virtual-scroll-table {
  max-height: 600px;
  overflow: auto;
}*/
.cart-table tr, .cart-items tr{
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }
  .cart-items tr:first-child{
    border-top: none;
  }
  .cart-items tr:last-child{
    border-bottom: none;
  }
  .cart-items td{
    width: 33% !important;
    white-space: nowrap;
  }
  .cart-items td:second-child{
    text-align: center;
  }
  .cart-items td:nth-child(3){
    text-align: right;
  }
  
  .cart-table td, .cart-items td{
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
    padding-left: .85rem !important;
    padding-right: .85rem !important;
  }
  .cart-table td:nth-child(2){
    text-align: right;
  }

  .actions a{
    font-size: 1.3rem !important;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .cart-quantity a{
    font-size: 1.2rem !important;
  }
  .background-image-maker {
    background-color: transparent !important;
  }
  .cart-table{
    margin-top:1rem !important;
  }
  .c-table, .cart-table{
    width: 100% !important;
  }
  .c-table td, .ctable td{
    font-size: .7rem;
  }
  .v-input input {
    min-height: 32px !important;
}
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import Pusher from 'pusher-js'
import {ability} from '../../services/ability'
import VueElementLoading from "vue-element-loading"

export default {
    name:"Receipt",
    components: {
      VueElementLoading
    },
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          imageRules: [
            value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
          ],
          amt: {},
          show: false,
          items: [],
          display: 'none',
          height: '100% !important',
          filter: '',
          search:null,
          valid: true,
          searchunit:null,
          dialog: false,
          updatedialog: false,
          addstockdialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Reports',
              disabled: false,
              href: '',
            },
            {
              text: 'Sales Receipts',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: '#',
              align: 'start',
              value: '#',
            },
            { text: 'Date', value: 'date' },
            { text: 'Receipt #', value: 'receipt' },
            { text: 'Total Amt(Kes)', value: 'totalamount' },
            { text: 'Action', value: 'action' },
           ],
          isBusy: false,
          loading: false,
           disabled: false,
            updateModal: null,
          start: 0,
          timeout: null,
          rowHeight: 24,
          perPage: 25,
          searchRes: "",
          active_store:null
        }
    },
    beforeMount() {
      if(!ability.can('inventory-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    serverPrefetch () {
      return this.getReports()
    },
    mounted(){      
      if(!this.reports || this.reports.length == 0) {
        this.getReports()
      }
    },
    computed: {
      filteredRows() {
        return this.$store.state.receipts.filter(row => {
          const package_code = row.package_code.toString().toLowerCase();
          const searchTerm = this.filter.toLowerCase();

          return package_code.includes(searchTerm);
        }).slice(this.start, this.perPage+this.start);
      },
      startHeight() {
        return this.start * this.rowHeight - 32;
      },
      endHeight() {
        return this.rowHeight * (this.$store.state.receipts.length - this.start);
      }
      
    },
    methods:{
    async printReceipt() {

        await this.$htmlToPaper('printReceipt', { name: '_blank' }, () => {
          
        });

      },
    onScroll(e) {
        // debounce if scrolling fast
        this.timeout && clearTimeout(this.timeout);
   
        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.$store.state.receipts.length ?
            this.$store.state.receipts.length - this.perPage: rows;

          this.$nextTick(() => {
            e.target.scrollTop = scrollTop;
          });
        }, 20);
      },
      async getReports(){
        await axios.get('/sales-receipts').then(response=>{
            if(response.data.status == parseInt(200)) {
              this.active_store = response.data.active_store
                this.receipts = response.data.receipts
            }
        }).catch(error=>{
            console.log(error)
            this.receipts = []
        })
    },
    async showReceipt(code){
            this.show = true

            this.receipt_no = code
            await axios.get(`/receipt-details/${code}`).then(response=>{
                 this.show = false
                if(response.data.status == parseInt(200)) {
                    this.items = response.data.items
                    this.amt = response.data.amt
                    this.display = 'block'
                }
            }).catch(error=>{
                this.show = false
                console.log(error)
            })
        },
        async refund(){
          console.log(this.receipt_no)
            await axios.get(`/return-receipt/${this.receipt_no}`).then(response=>{
                if(response.data.status == parseInt(200)) {
                    this.getReceipts()
                }
            }).catch(error=>{
                console.log(error)
                this.items = []
            })
        }
  }
}
</script>

