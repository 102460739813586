<template>

 <div class="Login">
       <v-container fluid fill-height style="margin-top:1rem">
          <v-layout align-center justify-center>
             <v-flex xs12 sm8 md4>
              <v-form  enctype="multipart/form-data" v-model="valid" ref="form">
                <v-card class="card-shadow px-3">
                   <v-card-title color="primary">
                        <v-row>
                          <v-col cols="12" class="px-0">
                            <img src="/assets/tpos.png" style="width:20% !important;text-align:center">
                            <h2 class="card-title fw-bold mb-0 mt-3">Forgot Password? 🔒</h2>
                            <p class="card-text mb-3">Enter your email and we'll send you instructions to reset your password</p>
                          </v-col>
                        </v-row>
                      </v-card-title>
                   <v-card-text>
                       <v-col
                          cols="12"
                          sm="12"
                          md="12"
                        >
                         <v-text-field
                            id="email"
                            prepend-icon="mdi-email"
                            name="email"
                            label="Email Address"
                            type="text"
                            v-model="form.email"
                            :rules="emailRules"
                         ></v-text-field>
                       </v-col>
                   </v-card-text>
                   <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary"
                      :loading="loading"
                      :disabled="!valid"
                      @click="resetpassword"
                      >Send reset link</v-btn>
                   </v-card-actions>
                   <p class="text-center" style="padding:1rem"><router-link :to="{ name: 'Login' }"><i data-feather="chevron-left"></i> Back to login</router-link></p>
                </v-card>
               </v-form>
             </v-flex>
          </v-layout>
       </v-container>
  </div>

</template>
<style scoped>
  .col-sm-12{
    padding: 0 !important;
  }
</style>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ForgotPassword',
    components: {
    },
    data: () => ({
      valid: true,
      fieldRules: [
      (v) => !!v || 'Field is required'
      ],
      emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      loading: false,
      disabled: false,
      form: {
        email: null,
      }
    }),
    computed: {
      ...mapGetters([
        'isLogged'
        ])
    },
    methods: {
       resetpassword() {

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('email',this.form.email)

            axios.post("/forgot/password", fd, config).then(response=>{

              this.loading = false
              this.disabled = false

              if(response.data.status === 404) {
                this.$toast.error(response.data.message,'Error',{position: 'topRight',timeout: 7000,})
              }
              if(response.data.status === 200) {
                this.$toast.success(response.data.message,'Success',{position: 'topRight',timeout: 7000,})
              }
              
            })
            .catch(() => {

              this.loading = false
              this.disabled = false

            })

          }

        }
    }
}
</script>