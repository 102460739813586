<template>
    <!-- BEGIN: Content-->
    <div class="Pos" id="app">
      <v-container fluid style="height:93vh !important">
        <v-row>
          <v-col cols="12" md="8" sm="12">
            <v-card class="card-shadow py-0" style="height:92vh !important">
              <v-card-title>
                <v-row>
                  <v-col cols="12" md="4" sm="12" style="margin-bottom:0 !important;padding-bottom:0 !important">
                    <div v-if="cashdrawer != null">
                      <v-btn 
                        @click="openCashRegister"
                        v-if="cashdrawer.opening_cash != null && cashdrawer.closing_cash === null"
                        class="ma-2"
                        color="primary"
                        outlined
                        :loading="cashloading"
                      >
                        <v-icon left>
                          mdi-check
                        </v-icon>
                        Cash Register
                      </v-btn>
                      <v-btn
                        @click="openCashRegister"
                        v-else
                        class="ma-2"
                        color="#f9780d"
                        outlined
                        :loading="cashloading"
                      >
                        <v-icon left>
                          mdi-close
                        </v-icon>
                        Cash Register
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn
                        @click="openCashRegister"
                        class="ma-2"
                        color="#f9780d"
                        outlined
                        :loading="cashloading"
                      >
                        <v-icon left>
                          mdi-close
                        </v-icon>
                        Cash Register
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" style="margin-bottom:0 !important;padding-bottom:0 !important">
                    <v-text-field
                      v-model="searchRes"
                      append-icon="mdi-magnify"
                      @focus="onFocus"
                      @blur="onBlur"
                      ref="searchFocus"
                      label="Search"
                      :search-input.sync="searchitem"
                      clearable
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="12" style="margin-bottom:0 !important;padding-bottom:0 !important">
                    <v-autocomplete
                        :items="categories"
                        label="Select category"
                        v-model="selectedCategory" 
                        item-text="category_name"
                        item-value="id"
                        :search-input.sync="searchcategory"
                        v-on:change='changeCategory'
                        clearable
                        @clear:append="selectedCategory = null"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" style="justify-content:end;margin-top:0 !important;padding-top:0 !important;padding-right:0 !important">
                    <v-btn
                      color="grey darken-1"
                      text
                      @click="toggleGrid = !toggleGrid"
                      v-if="!toggleGrid"
                      style="float:right;padding:0 !important"
                    >
                      <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                    <v-btn
                      color="grey darken-1"
                      text
                      v-if="toggleGrid"
                      @click="toggleGrid = !toggleGrid"
                      style="float:right;padding:0 !important"
                    >
                      <v-icon>mdi-view-grid</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
             </v-card-title>
              <v-card-text style="overflow-y:auto;height:69vh !important" v-if="toggleGrid">

                <v-row>
                    <v-col sm="6" md="4" lg="3" xl="2" cols="12" :key="index" v-for="(item, index) in filteredItems" @click="addCart(item)" style="cursor:pointer">
                      <v-card class="card-shadow">
                        <v-img
                          src="/assets/no-image.jpg"
                          class="white--text align-end"
                          height="120px"
                          gradient="to bottom, rgba(58,85,83,.4), rgba(58,85,83,.4)"
                        >
                          <v-card-title>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title class="text-h6" style="width:100% !important;font-size: .9rem !important;white-space:normal !important;word-break:break-all !important">
                                    {{ item.item_name }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle class="my-1" style="font-size: .85rem !important;">Kes.{{ item.price | currency('') }}</v-list-item-subtitle>
                                  <!-- <v-list-item-subtitle class="my-1" style="font-size: 1.1rem !important;">
                                      <div class="qnty" v-if="item.current_stock == null">{{ item.quantity }}</div>
                                      <div class="qnty" v-if="item.current_stock != null && item.current_stock != 0">{{ item.current_stock }}</div>
                                      <div class="qnty" v-if="item.current_stock != null && item.current_stock == 0"  :style="[item.current_stock == 0 ? {'width': '100%', 'color':'#E3AF00'} : {'width': '40px'}]">Out of Stock</div>
                                  </v-list-item-subtitle> -->
                                </v-list-item-content>

                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-col>
                </v-row>
              </v-card-text>
              <v-card-text class="vscroll" style="overflow-y:auto;" v-if="!toggleGrid">
                <v-row>
                  <v-virtual-scroll
                    :items="filteredItems"
                    :item-height="70"
                    style="height:69vh !important"
                  >
                    <template v-slot="{ item, index }">

                      <v-list-item :key="index" @click="addCart(item)">
                        <v-list-item-action>
                          <v-img
                            src="/assets/no-image.jpg"
                            class="white--text"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                          />
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title class="text-h6" style="width:100% !important;font-size: .9rem !important;white-space:normal !important;word-break:break-all !important">
                            {{ item.item_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="my-1" style="font-size: .85rem !important;">Kes.{{ item.price | currency('') }}</v-list-item-subtitle>
                         <!--  <v-list-item-subtitle class="my-1" style="font-size: 1.1rem !important;">
                              <div class="qnty" v-if="item.current_stock == null">{{ item.quantity }}</div>
                              <div class="qnty" v-if="item.current_stock != null && item.current_stock != 0">{{ item.current_stock }}</div>
                              <div class="qnty" v-if="item.current_stock != null && item.current_stock == 0"  :style="[item.current_stock == 0 ? {'width': '100%', 'color':'#E3AF00'} : {'width': '40px'}]">Out of Stock</div>
                          </v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>

                      <v-divider></v-divider>

                  </template>
                </v-virtual-scroll>
                </v-row>
              </v-card-text>
              <v-card-text style="position:absolute !important;bottom:0 !important;margin-bottom:.5rem !important">
                <v-row v-show="bcodeinput">
                  <v-col cols="12" md="6" lg="5" xl="4" sm="12" style="margin-bottom:0 !important;padding-bottom:0 !important">
                    <v-text-field
                    type="number"
                    v-on:blur="searchBarcodeBlur"
                      v-model="searchBarcode"
                      ref="barcodeinput"
                      append-icon="mdi-barcode"
                      label="Search Barcode"
                      single-line
                      hide-details
                      @keypress="onlyNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> 
          </v-col>

          <v-col cols="12" md="4" sm="12" style="z-index:100 !important">
            <div style="width:100% !important;">
              <v-card class="card-shadow py-0" style="margin-top:-6vh">
                <v-card-actions style="padding:0 !important" class="shadow">
                    <v-row class="actions py-0 mt-0">
                      <v-col sm="4" md="4" class="d-flex justify-content-center align-items-center">
                          <v-btn
                            color="primary lighten-2"
                            @click="holdCart"
                            text
                            block
                          >
                            <v-icon style="font-size:20px;">mdi-recycle</v-icon>
                          </v-btn>
                      </v-col>
                      <v-col sm="4" md="4" class="bfloat d-flex justify-content-center align-items-center">
                          <v-badge
                            color="primary accent-4"
                            :content="`${this.$store.state.heldcart.length}`"
                            class="notificationbell mr-10"
                          >
                            <v-btn
                              color="primary lighten-2"
                              @click="showHeldItems"
                              text
                              block
                            >
                              <v-icon style="font-size:20px;">mdi-format-list-checks</v-icon>
                            </v-btn>
                          </v-badge>
                      </v-col>
                      <v-col sm="4" md="4" class="d-flex justify-content-center align-items-center">
                        <v-btn
                            color="primary lighten-2"
                            @click="clearCart"
                            text
                            block
                          >
                            <v-icon style="font-size:20px;">mdi-cancel</v-icon>
                          </v-btn>
                      </v-col>
                    </v-row>
                </v-card-actions>
               <v-card-text style="height:62vh !important;overflow-y: auto;">
                <div class="row mb-1 mx-0" style="overflow-y: auto;">
                    <div class="col-sm-12 col-md-12 px-0">
                        <v-simple-table dense style="width:100% !important;overflow-x:hidden !important;overflow-y: auto;">
                          <template v-slot:default>
                            <tr :key="item.id" v-for="item in $store.state.cart">
                                <td style="text-transform:capitalize;font-weight:500"><a href="javascript:void(0)" @click="removeItem(item)">{{ item.item_name }}</a></td>
                                <td>
                                    <div class="d-flex justify-content-center cart-quantity">
                                        <div class="pl-0 mx-0">
                                            <v-btn
                                              color="black lighten-2"
                                              @click="removeFromCart(item)"
                                              text
                                              block
                                            >
                                              <v-icon>mdi-minus</v-icon>
                                            </v-btn>
                                        </div> 
                                        <div class="align-self-center px-2">{{ item.sum }}</div> 
                                        <div>
                                          <v-btn
                                              color="black lighten-2"
                                              @click="addCart(item)"
                                              text
                                              block
                                            >
                                              <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </td>
                                <td><div style="display:flex;justify-content:space-between !important;align-items:center !important;width:100% !important">{{ item.totalPrice | currency('') }}
                                    <v-btn
                                      color="red lighten-2"
                                      @click="removeItem(item)"
                                      text
                                      block
                                    >
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                  </div>
                                </td>
                            </tr>
                          </template>
                        </v-simple-table>
                    </div>
                </div>
              </v-card-text>
              </v-card>

              <v-card class="card-shadow py-0 mt-5">
                <v-card-text style="padding:0 16px !important">
                  <v-row>
                      <v-col cols="12">
                          <table class="w-100 cart-table" style="margin-top: 0 !important">
                              <tr>
                                  <td><b style="font-size:1rem">Sub Total</b></td>
                                  <td style="text-align:right"><b style="font-size:1rem;">{{ subtotalprice | currency('') }}</b></td>
                              </tr>
                              <tr>
                                  <td>Tax</td>
                                  <td style="text-align:right">16%</td>
                              </tr>
                              <!-- <tr>
                                  <td>Discount on each item(%)&nbsp;&nbsp;

                                    <popper
                                      trigger="clickToToggle"
                                      :options="{
                                        placement: 'top',
                                        modifiers: { offset: { offset: '0,10px' } }
                                      }">
                                      <div class="popper d-none">
                                        <input type="text" class="form-control" id="discount_item" name="discount_item" placeholder="Enter discount" v-on:keyup="addDiscountItem" v-model="discount_on_items" autofocus />
                                      </div>
                                      <span style="cursor:pointer" class="icon-note" slot="reference"></span>
                                    </popper>

                                  </td>
                                  <td style="text-align:right">0%</td>
                              </tr>
                              <tr>
                                  <td>Discount on total&nbsp;&nbsp;

                                    <popper
                                        trigger="clickToToggle"
                                        :options="{
                                          placement: 'top',
                                          modifiers: { offset: { offset: '0,10px' } }
                                        }">
                                        <div class="popper">
                                          <input type="text" class="form-control" id="discount_total" v-on:keyup="addDiscountTotal" v-model="discount_on_total" name="discount_total" placeholder="Enter discount" autofocus />
                                        </div>
                                        <span style="cursor:pointer" class="icon-note" slot="reference"></span>
                                      </popper>

                                    </td>
                                  <td style="text-align:right">{{ discount }}%<span v-if="discount > 0" style="cursor:pointer;margin-left:17%" class="icofont-trash text-danger" @click="removeDiscount()"></span></td>
                              </tr> -->
                              <tr>
                                  <td><b style="font-size:1rem">Total</b>&nbsp;&nbsp;<span class="icon-note"></span></td>
                                  <td style="text-align:right"><b style="font-size:1rem">{{ totalprice | currency('') }}</b></td>
                              </tr>
                        </table>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="12">
                        <v-btn
                          color="black lighten-2 white--text"
                          @click="showModal"
                          class="checkout"
                          block
                        >
                          Pay
                        </v-btn>
                      </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

            </div>
          </v-col>
        </v-row>
      </v-container>


      <!--cashdrawer dialog -->
      <v-dialog
        v-model="cashdrawerModal"
        width="400px"
        >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <v-row>
              <v-col cols="12" class="d-flex" style="align-items:center !important;justify-content:space-between !important">
                <span class="text-h6">Cash Drawer</span>
                <div v-if="cashdrawer != null">
                  <v-chip
                    v-if="cashdrawer.opening_cash != null && cashdrawer.closing_cash === null"
                    class="ma-2"
                    color="primary"
                    label
                    outlined
                  >
                    Opened
                  </v-chip>
                  <v-chip
                    v-else
                    class="ma-2"
                    color="red"
                    label
                    outlined
                  >
                    Closed
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="d-flex" style="align-items:center;justify-content:space-between;" v-if="cashdrawer != null">
                    <span style="padding:0;font-weight:500" v-if="cashdrawer.opening_cash != null">Opening Amount: <b>{{ cashdrawer.opening_cash | currency('') }}</b></span>
                    <span style="padding:0;font-weight:500" v-if="cashdrawer.closing_cash != null">Closing Amount: <b>{{ cashdrawer.closing_cash | currency('') }}</b></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row class="d-flex" v-if="cashdrawer === null" style="align-items:center !important;justify-content:center !important">
                      <v-col cols="12" md="9">
                        <v-text-field
                          label="Opening Amount"
                          v-model="item.opening_amount"
                          ref="openingAmt"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-btn
                          color="accent darken-1"
                          text
                          :loading="loading"
                          @click="submitOpenAmt"
                        >
                          Open
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-items-center" v-if="cashdrawer != null && cashdrawer.closing_cash === null">
                      <v-col cols="12">
                        <v-alert
                          text
                          dense
                          color="accent"
                          border="left"
                          style="width:100%"
                        >
                          <vue-element-loading
                            :active="showamt"
                            spinner="bar-fade-scale"
                            color="#FF6700"
                            size="30"
                            background-color="#FFEBE5"
                          />
                          Expected Amount:  <b>{{ expected_amt | currency('') }}</b>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <div v-if="cashdrawer != null && cashdrawer.closing_cash === null" class="mb-2">
                      <v-row class="mt-2">
                        <v-col cols="12">
                          <v-textarea
                            label="Note"
                            rows="3"
                            v-model="item.note"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex" style="align-items:center !important;justify-content:center !important">
                        <v-col cols="12" md="9">
                          <v-text-field
                            label="Closing Amount"
                            v-model="item.closing_amount"
                            ref="closingAmt"
                          ></v-text-field>
                        </v-col>
                          <v-col cols="12" md="3">
                            <v-btn
                              color="error darken-1"
                              text
                              :loading="loading"
                              @click="submitCloseAmt"
                            >
                              Close
                            </v-btn>
                          </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="cashdrawerModal = false"
              >
                Close
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end cash drawer -->

      <!--Todays modal-->
      <v-dialog
        v-model="todaysSales"
        max-width="600px"
        >
        <v-card>
          <v-card-title>
            <span class="text-h6">Today's Sales Summary</span>
          </v-card-title>

          <v-card-text>
              <v-simple-table dense>
                  <template v-slot:default>
                    <tr>
                        <td>Total Sale</td>
                        <td>{{ total_sale | currency('') }}</td>
                    </tr>
                    <tr>
                        <td>Opening Amt</td>
                        <td>{{ openingCash | currency('') }}</td>
                    </tr>
                    <tr>
                        <td><b>Profit</b></td>
                        <td><b>{{ todayProfit | currency('') }}</b></td>
                    </tr>
                  </template>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="todaysSales = false"
              >
                Close
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end todays sales -->

      <!--item return modal-->
      <v-dialog
        v-model="itemreturndialog"
        max-width="600px"
       >
        <v-card :key="index" v-for="(held, index) in this.$store.state.heldcart">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <h3>HELD ITEMS</h3>
              </v-col>
              <v-col cols="12" class="d-flex" style="align-items:center;justify-content:space-between">
                <v-btn
                  text
                >
                  <h3>Customer {{ ++index }}</h3>
                </v-btn>
                <v-btn
                  color="primary lighten-1"
                  text
                  @click="returnItem(held)"
                >
                  <v-icon>
                    mdi-undo-variant
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <table class="w-100 cart-items ctable">
                        <tr>
                            <td><b>ITEM</b></td>
                            <td><b>QTY</b></td>
                            <td><b>PRICE</b></td>
                        </tr>
                        <tr :key="itm.id" v-for="itm in held">
                            <td style="text-transform:capitalize;font-weight:500">{{ itm.item_name }}</td>
                            <td>
                              x {{ itm.sum }}
                            </td>
                            <td>{{ itm.price | currency('') }}</td>
                        </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="itemreturndialog = false"
              >
                Close
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- item return user -->

      <!-- payment dialog -->
      <v-dialog
        v-model="pModal"
        fullscreen
        hide-overlay
        >
        <v-card class="paymentcard">
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-btn
                  icon
                  dark
                  @click="pModal = false"
                  style="float:right !important;color:#000 !important"
                >
                  <v-icon style="font-size:2.5rem !important;">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row style="margin-top:1rem !important">
              <v-col cols="12" md="5" lg="5" id="printReceipt" style="display:none">
                <v-row>
                  <v-col cols="12" md="12" lg="12" class="mx-auto printstyle">
                    <h4 class="text-center" style="margin-top:6% !important;margin-bottom:6% !important;font-size:16px;text-transform:uppercase !important">{{ active_store.store_name }}</h4>
                    <h4 class="text-center" style="margin-top:5% !important;margin-bottom:5% !important;font-size:16px; border:1px solid #333">Sales Receipt</h4>
                    <table class="w-100 c-table">
                        <tr>
                            <td>Date:&nbsp;&nbsp;{{ date }}</td>
                            <td style="text-align:left !important">Time:&nbsp;&nbsp;{{ time }}</td>
                        </tr>
                        <tr>
                            <td>Receipt #:</td>
                            <td style="text-align:left !important">{{ receipt_no }}</td>
                        </tr>
                        <tr>
                            <td>Cashier:</td>
                            <td style="text-align:left !important">{{ $store.state.user.user.name }}</td>
                        </tr>
                    </table>
                    <table class="cart-items ctable" style="margin-top:5% !important">
                        <tr>
                            <td><b>ITEM</b></td>
                            <td><b>QTY</b></td>
                            <td><b>PRICE</b></td>
                        </tr>
                        <tr :key="item.id" v-for="item in $store.state.cart">
                            <td style="text-transform:capitalize;font-weight:500">{{ item.item_name }}</td>
                            <td>
                              x {{ item.sum }}
                            </td>
                            <td>{{ item.price | currency('') }}</td>
                        </tr>
                    </table>
                    <table class="w-100 cart-table citable">
                        <tr>
                            <td><b>Sub Total</b></td>
                            <td style="text-align:right"><b>{{ subtotalprice | currency('') }}</b></td>
                        </tr>
                        <tr>
                            <td>Discount</td>
                            <td style="text-align:right">{{ discount | currency('') }}%</td>
                        </tr>
                        <tr>
                            <td>Tax(VAT)</td>
                            <td style="text-align:right">16%</td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td style="text-align:right"><b>{{ totalprice | currency('') }}</b></td>
                        </tr>
                        <tr>
                            <td><b>Cash</b></td>
                            <td style="text-align:right"><b>{{ rcash | currency('') }}</b></td>
                        </tr>
                        <tr>
                            <td><b>Change</b></td>
                            <td style="text-align:right"><b>{{ rbal | currency('') }}</b></td>
                        </tr>
                    </table>
                  </v-col>
                </v-row>
                <v-row class="footer" style="margin-top:5% !important;">
                  <v-col cols="12" md="10" lg="10" class="mx-auto mt-4 text-center">
                    <p><b>Thank you for purchasing with Us!</b><br>
                    WITH YOU, ALL THE WAY</p>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="7" lg="7" class="mx-auto">
                <div class="text-center">
                  <h1 style="font-size:4rem !important;color:#000 !important;padding-bottom: 1.2rem;">{{ totalChange | currency('') }}</h1>
                  <small style="font-size:1.1rem;font-weight:bold;" v-html="dueChange">Amount Due</small>
                  <div class="form-group" style="margin-top:7%">
                      <v-row>
                        <v-col cols="12" md="7" class="mx-auto" style="text-align:center !important">
                            <v-text-field
                              label="Amount Paid"
                              ref="customerchange" 
                              v-model="cash"
                              @keyup="givechange"
                              class="cashinput"
                              style="text-align:center !important;"
                              solo
                            ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <v-col cols="12" md="7" class="mx-auto" style="text-align:left;">
                            <v-btn
                              color="black lighten-2 white--text"
                              @click="submitPurchase"
                              block
                              :disabled="disabled"
                            >
                              Save & Print Receipt
                            </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" @loaded="() => onLoaded()"></StreamBarcodeReader> -->
      <!-- <ImageBarcodeReader @decode="(a, b, c) => onDecodeImage(a, b, c)" @loaded="() => onLoadedImage()" @error="onError"></ImageBarcodeReader> -->

    </div>

</template>
<style>
.bfloat .v-badge__badge{
  bottom: calc(100% - 20px) !important;
    left: calc(100% - 15px) !important;
}
.vscroll .v-list-item--link:before{
  background:transparent !important;
}
.notificationbell .v-badge__badge{
      inset: auto auto calc(100% - 14px) calc(100% - 14px) !important;
}
.qnty{
    position: absolute;
    right: 0;
    font-size: .75rem;
    font-weight: bold;
    bottom: 0;
    background-color: rgba(255, 255, 255, .9);
    padding: .1rem .5rem;
    color: #DC3545;
    text-align: right;
  }
b{
  color: #000 !important;
}
td{
  color: #333 !important;
}
.btables table tr{
    margin-bottom: 1rem !important;
  }
  .cart-table tr, .cart-items tr{
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }
  .cart-items tr:first-child{
    border-top: none;
  }
  .cart-items tr:last-child{
    border-bottom: none;
  }
  .cart-items td{
    width: 33% !important;
    white-space: nowrap;
  }
  .cart-items td:second-child{
    text-align: center;
  }
  .cart-items td:nth-child(3){
    text-align: right;
  }
  
  .cart-table td, .cart-items td{
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
    padding-left: .85rem !important;
    padding-right: .85rem !important;
  }
  .cart-table td:nth-child(2){
    text-align: right;
  }

  .actions a{
    font-size: 1.3rem !important;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .cart-quantity a{
    font-size: 1.2rem !important;
  }
  .background-image-maker {
    background-color: transparent !important;
  }
  .cart-table{
    margin-top:1rem !important;
  }
  .c-table, .cart-table{
    width: 100% !important;
  }
  .c-table td, .ctable td{
    font-size: .7rem;
  }
  .v-input input {
    min-height: 32px !important;
}
</style>
<style scope>
.paymentcard{
  background-color: rgba(255,255,255,.95) !important;
}
.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
    min-width: 45px !important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 0 !important;
}
.v-card__title{
  /*display: block !important;*/
}
.v-responsive__content{
  width: 100% !important;
}
  .v-alert__content {
    display:flex;align-items:center !important;justify-content:space-between !important
  }
  .v-data-table__wrapper {
    width: 100% !important;
    overflow-x: hidden !important;
}

.cashinput .v-text-field__slot input{
  font-size: 3rem !important;
  padding: 2.2rem !important;
  text-align: center !important;
}
.cashinput.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
    border: 1px solid #0a0a0a !important;
}
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import {ability} from '@/services/ability'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import VirtualList from 'vue-virtual-scroll-list'
import VueElementLoading from "vue-element-loading"
import { StreamBarcodeReader } from "vue-barcode-reader"
import { ImageBarcodeReader } from "vue-barcode-reader"
import { VueOfflineMixin } from "vue-offline"

export default {
    name:"Pos",
    mixins: [VueOfflineMixin],
    components: {
      'popper': Popper,
      'virtual-list': VirtualList,
      VueElementLoading,
      ImageBarcodeReader,
      StreamBarcodeReader
    },
    data(){
        return {
          toggleGrid: false,
          searchfilter: '',
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          searchcategory: null,
          searchitem: null,
          selectedCategory: "all",
          options:{
              height:'70.3vh',
              size:12
          },
          bcodeinput: true,
          showamt: false,
          display: 'none',
          disabled:true,
          discount_on_items:null,
          discount_on_total:null,
          selectedCategory: null,
          search: '',
          cash: '',
          rcash: '',
          rbal: '',
          change: '',
          receipt_no: '',
          dueChange:'Amount Due',
          amtDue:0,
          date: '',
          time: '',
          subtotalprice: 0,
          totalprice: 0,
          discount:0,
          totalChange:0,
          active_store:'',
          // cashdrawer: '',
          expected_amt: '',
          total_sale: '',
          openingCash: '',
          todayProfit: '',
          searchRes: "",
          searchBarcode: null,
          item: {
            note: '',
            opening_amount: '',
            closing_amount: '',
            cashdrawer_id: ''
          },
          cashdrawerModal: false,
          todaysSales: false,
          itemreturndialog: false,
          pModal: false,
          overlay: false,
          cashregisterloading: false,
          cashrdisabled: false,
          cashrloading: false,
          valid: true,
          dialog: false,
          cashloading: false,
          updatedialog: false,
            loading: false,
        }
    },
    beforeMount() {
      // let storeI = this.$store.state.accountstore
      // if (!storeI) {
      //     this.$router.push({ name: 'storeList' })
      //     return
      // }
      // if(!ability.can('user-management', 'all')) {
      //   this.$router.push({ name: 'Dashboard' })
      //   return
      // }
    },
    serverPrefetch () {
      return this.getProducts()
    },
    destroyed () {
    this.$barcodeScanner.destroy()
    window.removeEventListener('keypress', this.doCommand);
  },
  methods: {

    onFocus() {
      this.bcodeinput = false
      this.$refs.searchFocus.focus()
    },
    onBlur() {
      if(this.$refs.barcodeinput !== undefined) {
        this.$refs.barcodeinput.focus()
      }
      this.bcodeinput = true
    },

    onlyNumber ($event) {
       let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
       if (keyCode === 45) { 
          $event.preventDefault();
       }
    },

    onDecodeImage(a, b, c) {
      console.log(a);
    },
    onLoadedImage () {
     console.log("loaded") 
   },
    onDecode (result) { 
      console.log(result) 
    },
    onLoaded () { 
      console.log("loaded") 
    },
    onError (result) { 
      console.log(result) 
    },

    async submitOpenAmt() {

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('opening_amount',this.item.opening_amount)

      await axios.post('/openregister', fd, config).then(response=>{
        this.loading = false
        this.cashrdisabled = false
        this.res = response.data.status
        if(this.res === parseInt(200))
          this.cashDrawer()

      }).catch(error=>{
        this.loading = false
          console.log(error)
      })
    },
    async submitCloseAmt() {

      this.loading = true

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      let fd = new FormData();
      fd.append('cashdrawer_id',this.item.cashdrawer_id)
      fd.append('closing_amount',this.item.closing_amount)
      fd.append('note',this.item.note)

      await axios.post('/closeregister', fd, config).then(response=>{
        this.loading = false
        this.res = response.data.status
        if(this.res === parseInt(200))

          this.total_sale = response.data.total_price
          this.openingCash = response.data.opening_amt
          this.todayProfit = response.data.profit

          this.cashdrawerModal = false
          this.todaysSales = true

          this.cashDrawer()

      }).catch(error=>{
        this.loading = false
          console.log(error)
      })
    },
    onCloseSummary() {
      this.$router.push({name:"Dashboard"})
    },
    doCommand(e) {

      // key BACKSPACE
      if(parseInt(e.keyCode) === 32){

        this.pModal = true

        const current = new Date();
        const mdate = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
        const mtime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        this.date = mdate
        this.time = mtime

        let d = new Date();
        let n = d.valueOf();

        this.receipt_no = n
      }
      // key subtract
      if(parseInt(e.keyCode) === 45) {
        this.searchBarcode = null
      }
      // key eNTER
      if(e.code === "NumpadEnter") {

        if(this.disabled == false) {
          this.submitPurchase()
        }
        
      }
      // key letter D
      if(parseInt(e.keyCode) === 100){
        this.pModal = false
      }
      // console.log(e.keyCode);
    },
    onBarcodeScanned (barcode) {
      // var result = this.products.find(obj => {
      //   return parseInt(obj.barcode) === parseInt(barcode)
      // })

      // if(result !== undefined) {
      //   this.addCart(result)
      // }

    },
    searchBarcodeBlur() {
      this.bcodeinput = true
      if(this.$refs.barcodeinput !== undefined) {
        this.$refs.barcodeinput.focus()
      }
    },
    resetBarcode () {
      let barcode = this.$barcodeScanner.getPreviousCode()
    },
    addDiscountTotal: function() {
      if(this.discount_on_total == null || this.discount_on_total == "") {
        this.$store.dispatch('addDiscountTotal', 0)
      }else{
        this.$store.dispatch('addDiscountTotal', this.discount_on_total)
      }
      this.totalprice = window.localStorage.getItem('totalprice')
      this.totalChange = window.localStorage.getItem('totalprice')
      this.discount = window.localStorage.getItem('discountTotal')
    },
    addDiscountItem: function() {
      if(this.discount_on_items == null || this.discount_on_items == "") {
        this.$store.dispatch('addDiscountItem', 0)
      }else{
        this.$store.dispatch('addDiscountItem', this.discount_on_items)
      }
      
      this.totalprice = window.localStorage.getItem('totalprice')
      this.totalChange = window.localStorage.getItem('totalprice')
    },
    removeDiscount: function() {
      this.discount = 0
      this.$store.dispatch('addDiscountTotal', this.discount)
      this.totalprice = window.localStorage.getItem('totalprice')
      this.totalChange = window.localStorage.getItem('totalprice')
      this.discount_on_total = null
    },
    async getProducts(){
        this.$store
        .dispatch('getDashboard')
        .then(() => {
        })
        .catch(() => {
        })
    },
    async cashDrawer(){

        this.cashloading = true

        await axios.get('/getcashdrawer').then(response=>{
          this.cashloading = false
          if(response.status == parseInt(200)) {
            this.active_store = response.data.active_store
            this.$store.state.cashdrawer = response.data.cashdrawer
            if(response.data.cashdrawer === null) {
              this.cashdrawerModal = true
            }else {
              this.item.cashdrawer_id = response.data.cashdrawer.id
              this.cashdrawerModal = false
            }
          }
        }).catch(error=>{
          this.cashloading = false
            console.log(error)
        })
    },
    async expectedAmt(){
        await axios.get('/expectedAmt').then(response=>{
          this.showamt = false

          if(response.data.status == parseInt(200)) {
              this.expected_amt = response.data.amount
          }
        }).catch(error=>{
          this.showamt = false
            console.log(error)
        })
    },
    openCashRegister() {
      this.showamt = true

      this.expectedAmt()
      this.cashdrawerModal = true
    },
    givechange() {

      this.dueChange = "<emp style='color:#DD5145'>Customer's Change</emp>"

      const balance = this.cash - this.totalprice

      this.totalChange = balance
      this.rbal = balance
      this.rcash = this.cash

      // if(this.totalprice == 0){
      //   this.disabled = true
      // }

      if(balance >= 0){
        this.disabled = false
        this.dueChange = "<emp style='color:#DD5145'>Customer's Change</emp>"
      }else{
        this.disabled = true
      }
      
      if(balance < 0){
        this.totalChange = this.totalprice
        this.dueChange = "<emp style='color:#333'>Amount Due</emp>"
      }

    },
    showModal() {
      this.pModal = true

      const current = new Date();
      const mdate = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
      const mtime = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      this.date = mdate
      this.time = mtime

      let d = new Date();
      let n = d.valueOf();

      this.receipt_no = n
    },
    showHeldItems() {
      this.itemreturndialog = true
    },
    hideModal() {
        this.pModal = false
    },
    focusChange() {
      // this.$refs["customerchange"].$refs.input.focus()
      setTimeout(() => {
         this.$refs.customerchange.$refs.input.focus()
      })
    },
    print() {
      const prtHtml = document.getElementById('printReceipt').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '<style>.c-table td,.citable td,.ctable td,.footer p{font-size:.6rem!important}.footer p,.printstyle h4,.printstyle p{text-align:center!important;width:100%!important}.printstyle{margin-left:2px!important;margin-right:6px!important}.printstyle table{width:100%!important;padding:0!important;margin:0!important}.cart-items tr,.cart-table tr{border-top:1px solid #dee2e6;border-bottom:1px solid #dee2e6}.cart-items tr:first-child{border-top:none}.cart-items tr:last-child{border-bottom:none}.cart-items td{width:33%!important;white-space:nowrap}.cart-items td:second-child{text-align:center}.cart-items td:nth-child(3),.cart-table td:nth-child(2){text-align:right}.cart-items td,.cart-table td{padding-top:.18rem!important;padding-bottom:.18rem!important}.cart-items td:first-child{margin-right:.9rem!important}.actions{border-top:1px solid #dee2e6}.actions .col-md-4{border-right:1px solid #dee2e6}.actions .col-md-4:nth-child(3){border-right:none}.actions a{font-size:1.3rem!important;width:100%;height:100%;text-align:center}.cart-quantity a{font-size:1.2rem!important}.background-image-maker{background-color:transparent!important}</style>';

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    async submitPurchase() {

      this.print()

      if(this.networkStatus === "Online") {

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let fd = new FormData();
        fd.append('cart',JSON.stringify(this.$store.state.cart))
        fd.append('totalsale',window.localStorage.getItem('totalsale'))
        fd.append('totalprice',this.totalprice)
        fd.append('subtotalprice',this.subtotalprice)
        fd.append('receipt_no',this.receipt_no)
        fd.append('cashier',this.$store.state.user.user.name)
        fd.append('time',this.time)
        fd.append('date',this.date)
        fd.append('cash',this.rcash)
        fd.append('balance',this.rbal)
        fd.append('discount',this.discount)

        await axios.post('/solditems', fd, config).then(response=>{ }).catch(error=>{ console.log(error) })

          this.hideModal()
          this.clearCart()
          this.getProducts()

      }
      if(this.networkStatus === "Offline") {

        let offlinedata = {
              cart: JSON.stringify(this.$store.state.cart),
              totalsale: window.localStorage.getItem('totalsale'),
              totalprice: this.totalprice,
              subtotalprice: this.subtotalprice,
              receipt_no: this.receipt_no,
              cashier: this.$store.state.user.user.name,
              time: this.time,
              date: this.date,
              cash: this.rcash,
              balance: this.rbal,
              discount: this.discount
          }

        this.$store.dispatch('saveOfflineData', offlinedata)

        this.hideModal()
        this.clearCart()
        this.getProducts()

      }

      // const purchaseData = [
      //   {'cart': this.$store.state.cart},
      //   {'totalprice': this.totalprice},
      //   {'cash': this.rcash},
      //   {'balance': this.rbal}
      // ]
      

      // await this.$htmlToPaper('printReceipt', null, res => {
      //   this.hideModal()
      //   this.clearCart()
      //   this.getProducts()
      // });
    },
    addCart(item) {
      if(item.current_stock !== null && item.current_stock <= parseInt(0)) {
        this.$toast.error(`<b>${item.item_name}</b> is out of stock`,'Error',{position: 'topRight',timeout: 7000,})

        this.searchBarcode = null
        return
      }else {

        if(this.cashdrawer == null) {

          this.$toast.error(`First open <b>${this.active_store.store_name}</b> Cash Register`,'Error',{position: 'topRight',timeout: 7000,})

          this.openCashRegister()

          return

        }else {

          let index = this.$store.state.cart.filter(itm => parseInt(itm.id) === parseInt(item.id) )
          let qnty = this.products.filter(itm => parseInt(itm.id) === parseInt(item.id) )
          if(index.length > 0) {
            if(item.current_stock !== null) {
              if((parseInt(qnty[0].current_stock) - (parseInt(index[0].sum) + 1)) < 0) {
                this.$toast.error(`<b>${item.item_name}</b> is out of stock`,'Error',{position: 'topRight',timeout: 7000,})

                this.searchBarcode = null
                return
              }
            }else {
              if((parseInt(qnty[0].quantity) - (parseInt(index[0].sum) + 1)) < 0) {
                this.$toast.error(`<b>${item.item_name}</b> is out of stock`,'Error',{position: 'topRight',timeout: 7000,})

                this.searchBarcode = null
                return
              }
            }
          }else {
            if((parseInt(qnty[0].current_stock) - 1) < 0) {
              this.$toast.error(`<b>${item.item_name}</b> is out of stock`,'Error',{position: 'topRight',timeout: 7000,})

              this.searchBarcode = null
              return
            }
          }

          this.$store.dispatch('addToCart', item)
          this.totalprice = window.localStorage.getItem('totalprice')
          this.totalChange = window.localStorage.getItem('totalprice')
          this.subtotalprice = window.localStorage.getItem('subtotalprice')
          this.change = ''
          this.cash = ''
        }

        this.searchRes = ""
        this.searchBarcode = null

      }
    },
    returnItem(item) {
      if(this.$store.state.cart.length > 0) {

        this.$toast.error('<b>You have an incomplete sale</b>.<br>First complete the sales before returning held items','',{position: 'topRight',timeout: 7000,})
        
      }else {

        this.$store.dispatch('returnItem', item)
        this.totalprice = window.localStorage.getItem('totalprice')
        this.totalChange = window.localStorage.getItem('totalprice')
        this.subtotalprice = window.localStorage.getItem('subtotalprice')
        this.change = ''
        this.cash = ''

        this.itemreturndialog = false

      }
      
    },
    removeFromCart(item) {

      this.$store.dispatch('removeFromCart', item)
      this.totalprice = window.localStorage.getItem('totalprice')
      this.totalChange = window.localStorage.getItem('totalprice')
      this.subtotalprice = window.localStorage.getItem('subtotalprice')
      this.change = ''
      this.cash = ''
    },
    removeItem(item) {
      this.$store.dispatch('removeItem', item)
      this.totalprice = window.localStorage.getItem('totalprice')
      this.totalChange = window.localStorage.getItem('totalprice')
      this.subtotalprice = window.localStorage.getItem('subtotalprice')
      this.change = ''
      this.cash = ''
    },
    clearSearch() {
      this.search = "";
    },
    clearCart() {
      this.$store.dispatch('clearCart')
      this.totalprice = 0
      this.totalChange = 0
      this.subtotalprice = 0
      this.change = ''
      this.cash = ''
      this.discount = 0
      this.rcash = 0
      this.rbal = ''
    },
    holdCart() {
      if(this.$store.state.cart.length > 0 ) {
        this.$store.dispatch('holdCart')
        this.totalprice = 0
        this.totalChange = 0
        this.subtotalprice = 0
        this.change = ''
        this.cash = ''
        this.discount = 0
        this.rcash = 0
        this.rbal = ''
      }else {

        this.$toast.error('There is no items to hold','Error',{position: 'topRight',timeout: 7000,})

      }
    
    },
    toggle(index, value, discount) {
      const data = {'type':value, 'amount':discount}
      console.log(`update ${index} ${value} ${discount}`)
      this.$store.commit('discount', data)
    },
    changeCategory(event) {
      this.selectedCategory = event
    },
    clearSearch() {
      this.searchBarcode = null
    }

  },
  computed: {
    networkStatus() {
      return this.isOnline ? "Online" : "Offline"
    },
    products() {
      return this.$store.state.items
    },
    cashdrawer() {
      return this.$store.state.cashdrawer
    },
    filteredItems() {
      if(this.$store.state.items.length > 0) {

        return this.$store.state.items.filter(item => (item.current_stock === null || item.current_stock !== null && parseInt(item.current_stock) > 0) )
        .filter(item => {

          const itemname = item.item_name.toString().toLowerCase()

          // if (!this.search) return this.$store.state.items;
          let searchTerm = ''
          if(this.searchRes !== '' && this.searchRes !== null) {
            searchTerm = this.searchRes.toString().toLowerCase()
          }

          return itemname.includes(searchTerm)

        }).filter(item => {

          const code = item.item_name.toString()

           let searchCode = ''
           if(this.searchBarcode !== null) {
            searchCode = this.searchBarcode
          }

          return code.includes(searchCode)

        }).filter(item => {

          if(this.selectedCategory !== null) {
            return item.category_id == parseInt(this.selectedCategory)
          }else {
            return this.$store.state.items
          }

        });

      }
    },
    categories() {
      return this.$store.state.categories
    },
    soldItems() {
      return this.$store.state.cart
    },
    totalPrice() {
      let total = 0;

      for (let item of this.$store.state.cart) {
        total += item.totalPrice;
      }

      return total.toFixed(2);
    }
  },
  created () {

    if(this.$refs.barcodeinput !== undefined) {
      this.$refs.barcodeinput.focus()
    }

      const eventBus = this.$barcodeScanner.init(this.onBarcodeScanned, { eventBus: true })
      if (eventBus) {
        eventBus.$on('start', () => { 
        })
        eventBus.$on('finish', () => { 
        })
      }
    },
  mounted() {

    this.$on('offline', () => {})

    this.$on('online', () => {
      this.getProducts()

      this.cashregisterloading = true
      this.cashDrawer()

    })

    if(this.$refs.barcodeinput !== undefined) {
      this.$refs.barcodeinput.focus()
    }

    if(!this.filteredItems || this.filteredItems.length == 0) {
      this.getProducts()
    }
    if(this.networkStatus === "Online") {
      this.cashregisterloading = true
      this.cashDrawer()
    }
    
    this.totalprice = window.localStorage.getItem('totalprice')
    this.totalChange = window.localStorage.getItem('totalprice')
    this.subtotalprice = window.localStorage.getItem('subtotalprice')
    this.discount = window.localStorage.getItem('discountTotal')

    window.addEventListener('keypress', this.doCommand);

  },
  watch: {
    pModal(visible) {
      if (visible) {
        this.bcodeinput = false
        this.focusChange()
      } else {
        this.bcodeinput = true
      }
    },
    searchBarcode: {
      handler(newVal,oldVal) {
        var result = this.products.find(obj => {
          return parseInt(obj.barcode) === parseInt(newVal)
        })

        if(result !== undefined) {
          this.addCart(result)
        }else {
          
        }
      },
      immediate: true,
    },
    cashdrawerModal: {
      handler(newVal,oldVal) {
        if (newVal) {
          this.bcodeinput = false
          if(this.$refs.openingAmt !== undefined) {
            this.$refs.openingAmt.focus()
          }
          if(this.$refs.closingAmt !== undefined) {
            this.$refs.closingAmt.focus()
          }
        } else {
          this.bcodeinput = true
          if(this.$refs.barcodeinput !== undefined) {
            this.$refs.barcodeinput.focus()
          }
        }
      },
      immediate: true,
    }
  }


}
</script>