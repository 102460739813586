<template>
<v-app id="app">
    <v-app-bar app color="primary" elevation="0" dense v-if="isLogged">
        <v-app-bar-nav-icon @click="toggleMini = !toggleMini" color="white"></v-app-bar-nav-icon>
        <!-- <v-spacer></v-spacer> -->
        <span style="margin-left:3% !important;padding:.5rem 2rem;background:#fff;border-radius:30px;font-weight:500;cursor:pointer;" @click="logout()">Logout</span>
        <!-- <v-menu
          offset-y
          v-if="isLogged"
          >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="white--text ma-2 m-auto"
              v-bind="attrs"
              v-on="on"
              text
              icon
            >
              <v-avatar>
                <img
                  src="/app-assets/images/no_user.png"
                  alt="user"
                  style="width:35px;height:35px"
                >
              </v-avatar>
            </v-btn>
          </template>

          <v-list
          style="width:130px !important;border-radius:0 !important"
          >
           <v-list-item
              link 
            >
              <v-list-item-title style="padding:5px 5px !important;height: auto !important;margin:0 !important;font-size:15px;float:left !important">
                <v-icon>mdi-account-check-outline</v-icon>
                <span style="padding-left:3% !important">Profile</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              @click="logout()" 
            >
              <v-list-item-title style="padding:5px 5px !important;height: auto !important;margin:0 !important;font-size:15px;float:left !important">
                <v-icon>mdi-logout-variant</v-icon>
                <span style="padding-left:3% !important" @click="logout()">Logout</span>
              </v-list-item-title>
            </v-list-item>
           
          </v-list>
        </v-menu> -->
    </v-app-bar>
      <v-navigation-drawer 
          v-model="toggleMini" 
          v-if="isLogged"
          app
          :mini-variant.sync="mini"
          elevation="0"
          stateless
          light
          class="menudrawer"
          >
          <v-list color="brand primary" dense>
              <v-list-item class="py-2">
                  <v-list-item-content>
                      <router-link link :to="{name: 'Dashboard'}" style="text-decoration:none !important;color:inherit !important">
                        <v-list-item-title class="text-center">
                            <!-- <h3 class="font-weight-bold">Mobi POS</h3> -->
                            <img src="/assets/tpos_w.png" style="width:48% !important;text-align:center">
                        </v-list-item-title>
                      </router-link>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
          <v-list-item class="px-2 py-2 primary" style="justify-content:center;cursor:pointer;" v-if="isLogged" @click="showSyncModal">
             <span style="background:rgba(213, 239, 241,.5);padding:.2rem 2rem;border-radius:30px;color:#fff">
               Offline Sales: <span style="font-size:1.5rem !important">{{ $store.state.offlinesales.length }}</span>
             </span>
          </v-list-item>
          <v-list-item class="px-2 py-2 primary" @click="mini = !mini" v-if="isLogged">
              <v-list-item-avatar>
                  <v-icon color="white">mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="text-truncate font-weight-bold">
                  <span v-for="(store, index) in $store.state.user.user.store">
                    <b v-if="store.status == 'Active'" style="font-size:.85rem !important;color:#fff !important;text-transform:uppercase !important;font-weight:normal !important">{{ store.store.store_name }}</b>
                  </span>
              </v-list-item-content>
              <v-btn icon small>
                  <v-icon color="white" @click.stop="mini = !mini">mdi-chevron-left</v-icon>
              </v-btn>
          </v-list-item>
          <v-divider></v-divider>
          <v-list class="sidenav" v-if="isLogged">

            <v-list-item link to="/" v-if="$can('view-dashboard', 'all')">
              <v-list-item-icon>
                  <v-icon color="primary">mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/point-of-sale" v-if="$can('pos', 'all')">
              <v-list-item-icon>
                  <v-icon color="primary">mdi-printer-pos</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">POS</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- first list group -->
            <v-list-group
              v-for="item in products"
              :key="item.title"
              no-action
              v-if="$can('inventory-management', 'all')"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                link 
                :to="subItem.href"
              >
                <!-- <v-list-item-content> -->
                    <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                    <v-list-item-icon>
                      <v-avatar
                        color="rgba(0,0,0,.05)"
                        size="25"
                      >
                        <span class="black--text" v-text="subItem.icon"></span>
                      </v-avatar>
                    </v-list-item-icon>
                <!-- </v-list-item-content> -->
                
              </v-list-item>
              <v-divider></v-divider>
            </v-list-group>
            <!-- end first group -->

            <!-- third list group -->
            <v-list-group
              v-for="item in reports"
              :key="item.title"
              no-action
              v-if="$can('user-management', 'all')"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                link 
                :to="subItem.href"
              >
                <!-- <v-list-item-content> -->
                  <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                  <v-list-item-icon>
                      <v-avatar
                        color="rgba(0,0,0,.05)"
                        size="25"
                      >
                        <span class="black--text" v-text="subItem.icon"></span>
                      </v-avatar>
                    </v-list-item-icon>
                <!-- </v-list-item-content> -->
              </v-list-item>
              <v-divider></v-divider>
            </v-list-group>
            <!-- end third group -->

          </v-list>
      </v-navigation-drawer>
    <v-main>
        <v-container fluid>
          <v-row style="background:#405C5A;height:15rem;position:fixed;top:0;right:0;left:0" v-if="isLogged"></v-row>
            <v-row>
                <v-col cols="12" style="margin-top:0 !important">
                    <router-view></router-view>
                </v-col>
            </v-row>
        </v-container>
    </v-main>

    <!--sync offline data-->
      <v-dialog
        v-model="offlinedialog"
        max-width="350px"
       >
        <v-card>
          <v-card-text class="pb-0 px-2">
              <v-container>
                <v-row class="pt-4">
                  <v-col cols="12" style="align-items:center;justify-content:center;display:flex;flex-direction:column">
                    <span style="background:rgba(213, 239, 241,.8);padding:.4rem 2rem;border-radius:30px;color:#333">
               Offline Sales: <span style="font-size:1.5rem !important">{{ $store.state.offlinesales.length }}</span></span>
                    <p style="margin-top:1rem;font-size:.9rem !important;text-align:justify">Sync <b>Offline</b> sales to with online data. This ensures your sales are consistent and accurate.</p>
                    <p style="margin-top:1rem;font-size:.9rem !important;text-align:justify"><b>N/B</b>: Ensure you sync offline sales when your network is restored.</p>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="offlinedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary darken-1"
                text
                :loading="loading"
                @click="saveoffline"
              >
                Sync Data
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--sync offline data -->
</v-app>
</template>
<style>
  .notificationbell .v-badge__badge{
      inset: auto auto calc(100% - 24px) calc(100% - 24px) !important;
}
</style>
<style scoped>

.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group>.v-list-group__items>.v-list-item {
    padding-left: 64px !important;
}
.v-main{
      padding: 15vh 0px 0px 200px;
      padding-top: 6vh !important;
}
  .v-list.brand{
    padding: 0 !important;
  }
  .v-list, .v-list-item, .v-list-item__title{
    font-size: 14px !important;
  }
 
  .v-list-item{
    font-weight: normal !important;
  }
</style>
<script>
import { mapGetters } from 'vuex'
import { Can } from '@casl/vue'
import { VueOfflineMixin } from "vue-offline"
import axios from 'axios'

 export default {
  name: 'App',
  mixins: [VueOfflineMixin],
  components: {
    Can,
  },

  computed: {
    ...mapGetters([
      'isLogged'
    ]),
    alldashboard () {
      return this.$store.state.items
    },
    networkStatus() {
      return this.isOnline ? "Online" : "Offline"
    }
  },
  data: () => ({
    offlinedialog: false,
      sidebarMenu: true,
      toggleMini: true,
      loading: false,
      mini: false,
      active: false,
      products: [
        {
          title: 'Products',
          icon: 'mdi-sitemap',
          color: 'primary',
          items: [
            { title:"Items", href:"/products/list",icon:"I"},
            { title:"Categories", href:"/category/list",icon:"C"},
            { title:"Discounts", href:"/discount/list",icon:"D"}
          ]
        }
      ],
      reports: [
        {
          title: 'Reports',
          icon: 'mdi-chart-bar',
          color: 'primary',
          items: [
            { title:"Sales Summary", href:"/reports/sales-summary",icon:"SS"},
            { title:"Sales by Item", href:"/reports/sales-item",icon:"SI"},
            { title:"Sales by Category", href:"/reports/sales-category",icon:"SC"},
            { title:"Receipts", href:"/sales/receipts",icon:"R"},
          ]
        },
        {
          title: 'Settings',
          icon: 'mdi-cog-outline',
          color: 'primary',
          items: [
            { title:"Stores", href:"/store/list",icon:"S"},
            { title:"Taxes", href:"/tax/list",icon:"T"},
          ]
        },
        {
          title: 'User Config',
          icon: 'mdi-account-cog-outline',
          color: 'primary',
          items: [
            { title:"Users", href:"/users/list",icon:"U"},
            { title:"Permissions", href:"/permission/list",icon:"P"},
            { title:"Roles", href:"/role/list",icon:"R"},
          ]
        }
      ]
  }),
  mounted() {

    this.$on('offline', () => {
      this.$toast.warning('You have gone <b>Offline</b>. This will affect your interaction with the system.','Warning',{position: 'topRight',timeout: 7000,})
      console.log('you are offline')
    })

    this.$on('online', () => {
      this.$toast.success('You are back <b>Online</b>. Fetching data...','Info',{position: 'topRight',timeout: 7000,})
    })

    this.$parent.$on('toggleNav', () => {
      this.collapses = !this.collapses
      this.navwidth = !this.navwidth
      this.navleft = !this.navleft
      this.appadding = !this.appadding
      this.appleft = !this.appleft
      this.headerActive = !this.headerActive
      this.showIcon = !this.showIcon
    })

    if(this.isLogged) {
      if(!this.$store.state.items || this.$store.state.items.length == 0) {
        this.getDashboard()
      }
    }

     var pusher = new Pusher('520ce8cd4da6077ae6b2', {
        cluster: 'ap2'
      });
      // add package
      var channel = pusher.subscribe('addPackage');
      channel.bind('packageAdded', data => {
          this.$store.dispatch('addpackage', data.data)
      })
      // add
      var channel = pusher.subscribe('addStock');
      channel.bind('stockAdded', data => {
          this.$store.dispatch('addstock', data.data)
      })
      // new
      var channel = pusher.subscribe('createProduct');
      channel.bind('productCreated', data => {
          this.$store.dispatch('productnew', data.data)
      })
      // edit
      var editchannel = pusher.subscribe('updateProduct');
      editchannel.bind('productUpdated', data => {
          this.$store.dispatch('productupdate', data.data)
      })
      // delete
      var deletechannel = pusher.subscribe('deleteProduct');
      deletechannel.bind('productDeleted', data => {
          this.$store.dispatch('productdelete', data.data)
      })
    
  },
  serverPrefetch () {
      return this.getDashboard()
    },
  methods: {
    async saveoffline() {
      if(this.$store.state.offlinesales.length > 0) {

        if(this.networkStatus === "Online") {

          this.loading = true

          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          let fd = new FormData();
          fd.append('cart',JSON.stringify(this.$store.state.offlinesales))

          await axios.post('/saveofflinedata', fd, config).then(response=>{
              this.loading = false
              if(response.data.status === 200) {
                this.$store.dispatch('clearOfflineCart')
                this.$toast.success('Offline sales synced successfully','Success',{position: 'topRight',timeout: 7000,})

                this.getDashboard()

                this.offlinedialog = false
              }
              
            }).catch(error=>{
              this.loading = false
                console.log(error)
            })

        }else {

          this.$toast.warning("You are currently offline. Wait unitl you are online to sync data",'Warning',{position: 'topRight',timeout: 7000,})

        }

      }else {
        this.$toast.warning("You don't have offline sales",'Warning',{position: 'topRight',timeout: 7000,})
      }
    },
    showSyncModal() {
      this.offlinedialog = true
    },
    async getDashboard(){
      this.$store
        .dispatch('getDashboard')
        .then(() => {
        })
        .catch(() => {
        })
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
};
</script>
