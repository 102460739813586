<template>
    <!-- BEGIN: Content-->
    <div class="discountList">
      <v-container fluid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

            <v-btn
              color="#000000"
              class="white--text"
              depressed
              @click.stop="dialog = true"
            >
              Discount
              <v-icon
                right
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-3">
              <v-data-table
                dense
                :headers="fields"
                :items="discounts"
                class="elevation-0"
                loading="false"
                loading-text="Loading... Please wait"
              >
                <template v-slot:item.discount="{ item }">
                  {{ item.discount_name }}
                </template>
                <template v-slot:item.value="{ item }">
                  {{ item.value }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="edit(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="delete(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add Discount</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Discount Name*"
                      v-model="item.discount_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Value(%)*"
                      v-model="item.value"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update Discount</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Discount Name*"
                      v-model="item.updatediscount_name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      label="Value(%)*"
                      v-model="item.updatevalue"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>

<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import {ability} from '../../services/ability'

export default {
    name:"discountList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'Products',
              disabled: false,
              href: '',
            },
            {
              text: 'Discounts',
              disabled: false,
              href: '',
            },
          ],
          fields: [
             {
              text: 'Discount',
              align: 'start',
              value: 'discount',
            },
            { text: 'Value(%)', value: 'value' },
            { text: 'Action', value: 'action' },
           ],
          item:{
                discount_name:"",
                value: "",
                updatediscount_name:"",
                updatevalue: "",
                updateID: "",
                _method:"patch"
            },
            loading: false,
           disabled: false,
           discounts: [],
           isBusy: false,
            updateModal: null
        }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    mounted(){
      this.getDiscounts()
    },
    methods:{
      async getDiscounts(){
            await axios.get('/discount').then(response=>{
                this.discounts = response.data
            }).catch(error=>{
                console.log(error)
            })
        },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('discount_name',this.item.discount_name)
            fd.append('value',this.item.value)

            await axios.post('/discount', fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success("Discount added successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.getDiscounts()
                this.clear()
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

        }

        },
        clear () {
          this.$refs.form.reset()
        },
        async update(){

          if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('discount_name',this.item.updatediscount_name)
            fd.append('value',this.item.updatevalue)
            fd.append('_method',this.item._method)

            await axios.post(`/discount/${this.item.updateID}`, fd, config).then(response=>{
              this.loading = false
              this.disabled = false
              this.res = response.status
              if(this.res === parseInt(200))
                this.$toast.success("Discount updated successfully",'Success',{position: 'topRight',timeout: 7000,})
                this.updatedialog = false
                this.getDiscounts()
              this.clear()
              this.item.updateID = ''
            }).catch(error=>{
              this.loading = false
              this.disabled = false
                console.log(error)
            })

          }

        },
      async edit(id) {
        let index = this.discounts.filter(item => item.id === id );
        this.item.updatediscount_name = index[0].discount_name
        this.item.updatevalue = index[0].value
        this.item.updateID = index[0].id
        this.updatedialog = true
      },
      delete(id){
            if(confirm("Are you sure to delete discount ?")){
                axios.delete(`/discount/${id}`).then(response=>{
                  this.$toast.success('Discount deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                    this.getDiscounts()
                }).catch(error=>{
                    console.log(error)
                })
            }
        }

    }
}
</script>