<template>
    <!-- BEGIN: Content-->

    <div class="usersList">
      <v-container flid>

        <v-row>
          <v-col md="12" sm="12" class="d-flex" style="justify-content:space-between">
            <v-breadcrumbs
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>

            <v-btn
              color="#000000"
              class="white--text"
              depressed
              @click.stop="dialog = true"
            >
              User
              <v-icon
                right
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row>
          <v-col md="12" sm="12">
            <v-card class="card-shadow py-3">
              <v-data-table
                dense
                :headers="fields"
                :items="allusers"
                class="elevation-0"
                loading="false"
                :search="searchfilter"
                loading-text="Loading... Please wait"
              >
              <template v-slot:top>
                <v-text-field
                  v-model="searchfilter"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
              </template>
                <template v-slot:item.date="{ item }">
                  {{ item.created_at }}
                </template>
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item.telephone="{ item }">
                  {{ item.telephone }}
                </template>
                <template v-slot:item.email="{ item }">
                  {{ item.email }}
                </template>
                <template v-slot:item.roles="{ item }">
                  <v-chip
                    dark
                    small
                    v-for="(role, index) in item.roles" :key="index"
                  >
                    {{ role.name }}
                  </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editOrg(item.id)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item.id)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>


      <!-- add user dialog -->
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Add User</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Full Names*"
                      v-model="item.name"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Telephone*"
                      required
                      v-model="item.telephone"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Email Address*"
                      required
                      v-model="item.email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="roles"
                      label="Select roles"
                      v-model="item.role_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      multiple
                      :search-input.sync="searchrole"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="create"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- end user -->

      <!-- user update modal-->
      <v-dialog
        v-model="updatedialog"
        max-width="600px"
      >
        <v-card>

          <v-form  enctype="multipart/form-data" v-model="valid" ref="form">

          <v-card-title>
            <span class="text-h6">Update User</span>
          </v-card-title>

          <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Full Names*"
                      v-model="item.updatename"
                      required
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-text-field
                      label="Telephone*"
                      required
                      v-model="item.updatetelephone"
                      :rules="fieldRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Email Address*"
                      required
                      v-model="item.updateemail"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <v-autocomplete
                      :items="roles"
                      label="Select roles"
                      v-model="item.updaterole_id"
                      :value="item.updaterole_id"
                      item-text="name"
                      item-value="id"
                      :rules="fieldRules"
                      multiple
                      :search-input.sync="searchrole"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="updatedialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="!valid"
                @click="update"
              >
                Save
              </v-btn>
          </v-card-actions>

        </v-form>
        </v-card>
      </v-dialog>
      <!-- update user -->

    </div>

</template>
<style scope>
    @media (min-width: 576px){
        .modal-slide-in .modal-dialog {
            width: 48rem;
        }
    }
    .vs__selected, .vs__search,.vs__search:focus{
        color: #B4B7BD !important;
    }
    .vs__open-indicator,.vs__clear{
        fill:  #B4B7BD !important;
    }
</style>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import Pusher from 'pusher-js'
import {ability} from '../../services/ability'

export default {
    name:"usersList",
    data(){
        return {
          fieldRules: [
          (v) => !!v || 'Field is required'
          ],
          emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
          ],
          searchfilter: '',
          search:null,
          valid: true,
          searchrole:null,
          dialog: false,
          updatedialog: false,
          breadcrumbs: [
            {
              text: 'Dashboard',
              disabled: false,
              href: '/',
            },
            {
              text: 'User Config',
              disabled: false,
              href: '',
            },
            {
              text: 'Users',
              disabled: false,
              href: '',
            },
          ],
          item:{
                name:"",
                telephone:"",
                email:"",
                role_id: [],

                updatename:"",
                updatetelephone:"",
                updateemail:"",
                updaterole_id: [],

                updateID: "",
                _method:"patch"
            },
            isBusy: false,
            loading: false,
            tableloading:true,
           disabled: false,
           Organisation: [],
           Drivers: [],
           fields: [
             {
              text: 'Date',
              align: 'start',
              value: 'created_at',
            },
            { text: 'Name', value: 'name' },
            { text: 'Telephone', value: 'telephone' },
            { text: 'Email', value: 'email' },
            { text: 'Roles', value: 'roles' },
            { text: 'Action', value: 'action' },
           ],
            updateModal: null
        }
    },
    mounted(){

      if(!this.allusers || this.allusers.length == 0) {
        this.getUsers()
      }

    },
    serverPrefetch () {
      return this.getUsers()
    },
    computed: {
      allusers() {
        return this.$store.state.users
      },
      roles() {
        return this.$store.state.roles
      }
    },
    beforeMount() {
      if(!ability.can('user-management', 'all')) {
        this.$router.push({ name: 'Pos' })
        return
      }
    },
    methods:{
      async getUsers(){

        this.$store
          .dispatch('getUsers')
          .then(() => {
            this.isBusy = false
          })
          .catch(() => {
            this.isBusy = false
          })
      },
       async create(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.name)
            fd.append('telephone',this.item.telephone)
            fd.append('email',this.item.email)
            fd.append('roles',JSON.stringify(this.item.role_id))

            await axios.post('/users', fd, config).then(response=>{
                this.$toast.success('User added successfully','Success',{position: 'topRight',timeout: 7000,})
                this.loading = false
                this.disabled = false
                this.dialog = false
                this.item.name = ''
                this.item.telephone = ''
                this.item.email = ''
                this.item.role_id = ''
                this.clear()
                this.getUsers()
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
                this.dialog = false
              })

          }

        },
        clear () {
          this.$refs.form.reset()
        },
        async update(){

        if (this.$refs.form.validate()) {

          this.loading = true
          this.disabled = true

            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            let fd = new FormData();
            fd.append('name',this.item.updatename)
            fd.append('telephone',this.item.updatetelephone)
            fd.append('email',this.item.updateemail)
            fd.append('roles',JSON.stringify(this.item.updaterole_id))
            fd.append('_method',this.item._method)

            await axios.post(`/users/${this.item.updateID}`, fd, config).then(response=>{
                this.$toast.success('User updated successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getUsers()
                this.loading = false
                this.disabled = false
                this.updatedialog = false
                this.item.updatename = ''
                this.item.updatetelephone = ''
                this.item.updateemail = ''
                this.item.updateID = ''
              })
              .catch(() => {
                this.loading = false
                this.disabled = false
                this.updatedialog = false
              })

          }

        },
      async editOrg(id) {
        let index = this.allusers.filter(item => item.id === id );

        await axios.get(`/users/${index[0].id}`).then(response=>{
          this.item.updaterole_id = response.data.userRole

          this.item.updatename = index[0].name
          this.item.updatetelephone = index[0].telephone
          this.item.updateemail = index[0].email
          this.item.updateID = index[0].id
          this.updatedialog = true
          
        }).catch(error=>{
          this.loading = false
          this.disabled = false
            console.log(error)
        })

      },
      deleteItem(id){
            if(confirm("Are you sure to delete user ?")){

              axios.delete(`/users/${id}`).then(response=>{
                this.$toast.success('User deleted successfully','Success',{position: 'topRight',timeout: 7000,})
                this.getUsers()
              })
              .catch(() => {
                console.log("Unexpected error occured")
              })
              
            }
        }
    }
}
</script>